import { useState, useEffect } from 'react';

import qs from 'qs';
import { SegmentInfo, TravelSegment } from '../../types/journeyResults';
import { transformCallingPoints } from './JourneyInfoModal.utils';
import axiosHttp from '../../actions/axios';

export const useJourneyInfoAPI = (APIurl: string, travelSegments: Array<TravelSegment>) => {
  const [data, setData] = useState<SegmentInfo[][] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosHttp.get(`/journey-plan`, {
          params: {
            journeyId: APIurl,
          },
          paramsSerializer: (params) => qs.stringify(params, { encode: false }),
        });
        const callingPoints = transformCallingPoints(travelSegments, response.data);
        setData(callingPoints);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (data === null) {
      fetchData();
    }
  }, [APIurl, travelSegments, data]);

  return { data, loading, error };
};
