import styles from './TimePicker.module.scss';
import './TimePicker.css';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import WheelPicker, { PickerData } from '@fabharikad/scroll-picker';
import { nanoid } from 'nanoid';
import { toknTime } from '../../utils/journeyplanner';
import ArrowDown from '../../assets/arrow-down.svg?react';
import ArrowUp from '../../assets/arrow-up.svg?react';
import { ArrivalDepart, ToknTime, ToknUTC } from '../../types/journeyPlanner';

type TimeTypeArray = Array<PickerData>;

const hours: TimeTypeArray = [
  { id: '0', value: '00' },
  { id: '1', value: '01' },
  { id: '2', value: '02' },
  { id: '3', value: '03' },
  { id: '4', value: '04' },
  { id: '5', value: '05' },
  { id: '6', value: '06' },
  { id: '7', value: '07' },
  { id: '8', value: '08' },
  { id: '9', value: '09' },
  { id: '10', value: '10' },
  { id: '11', value: '11' },
  { id: '12', value: '12' },
  { id: '13', value: '13' },
  { id: '14', value: '14' },
  { id: '15', value: '15' },
  { id: '16', value: '16' },
  { id: '17', value: '17' },
  { id: '18', value: '18' },
  { id: '19', value: '19' },
  { id: '20', value: '20' },
  { id: '21', value: '21' },
  { id: '22', value: '22' },
  { id: '23', value: '23' },
];
const minutes: TimeTypeArray = [
  { id: '0', value: '00' },
  { id: '15', value: '15' },
  { id: '30', value: '30' },
  { id: '45', value: '45' },
];

export interface TimePickerRef {
  getToknTime: () => [ToknTime, ArrivalDepart];
  setIsToday: (isToday: boolean, date: Date) => void;
}

interface TimePickerProps {
  hour: string;
  minute: string;
  arrival: ArrivalDepart;
  startDate: ToknUTC;
}

const TimePicker = React.forwardRef<TimePickerRef, TimePickerProps>(
  ({ hour, minute, startDate, arrival }, ref) => {
    const [isArrivalTab, setIsArrivalTab] = useState<ArrivalDepart>(arrival);
    const [selectedMin, setSelectedMin] = useState(minute);
    const [selectedHour, setSelectedHour] = useState(hour);
    const [startMinute, setStartMinute] = useState(minute);
    const [startHour, setStartHour] = useState(hour);
    const [disableHourMinute, setDisableHourMinute] = useState(false);
    const [isValidTime, setIsValidTime] = useState(true);
    const [date, setDate] = useState(new Date());

    const hourRef = useRef(null);
    const minRef = useRef(null);

    const onTabChange = useCallback((isArrivalTab: ArrivalDepart) => {
      setIsArrivalTab(isArrivalTab);
    }, []);

    const onHourChange = useCallback((data: PickerData) => {
      setSelectedHour(data?.id);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        getToknTime: (): [ToknTime, ArrivalDepart] => {
          if (!isValidTime) {
            console.log('Invalid time');
            return [null, null];
          }
          const currentDateTime = dayjs(date)
            .hour(parseInt(selectedHour))
            .minute(parseInt(selectedMin))
            .second(0)
            .millisecond(0);

          return [toknTime(currentDateTime), isArrivalTab];
        },
        setIsToday: (isToday: boolean, date: Date): void => {
          setDisableHourMinute(isToday);
          setDate(date);
          if (isToday) {
            const currentDateTime = dayjs(startDate);
            setStartHour(String(currentDateTime.hour()));
            setStartMinute(String(currentDateTime.minute()));
          }
        },
      }),
      [date, isArrivalTab, isValidTime, selectedHour, selectedMin, startDate]
    );

    useEffect(() => {
      let isValid = true;
      let enableHour = true;
      let enableMinute = true;

      if (disableHourMinute) {
        if (+startMinute > +selectedMin && +startHour === +selectedHour) {
          enableMinute = false;
          isValid = false;
        }

        if (+startHour > +selectedHour) {
          enableHour = false;
          enableMinute = false;
          isValid = false;
        }
      }

      hourRef?.current?.enable(enableHour);
      minRef?.current?.enable(enableMinute);
      setIsValidTime(isValid);
    }, [disableHourMinute, selectedHour, selectedMin, startHour, startMinute]);

    const onMinChange = useCallback((data: PickerData) => {
      setSelectedMin(data?.id);
    }, []);

    const handleHourScrollUp = useCallback(() => {
      hourRef?.current?.scroll(-70);
    }, [hourRef]);

    const handleHourScrollDown = useCallback(() => {
      hourRef?.current?.scroll(70);
    }, [hourRef]);

    const handleMinScrollUp = useCallback(() => {
      minRef?.current?.scroll(-70);
    }, [minRef]);

    const handleMinScrollDown = useCallback(() => {
      minRef?.current?.scroll(70);
    }, [minRef]);

    const scrollPicker = useMemo(
      () => (
        <div className={styles.timePickerWrapper}>
          <div className={styles.wheelHourMinWrapper}>
            <span className={styles.arrows} onClick={handleHourScrollUp}>
              <ArrowUp />
            </span>
            <WheelPicker
              ref={hourRef}
              data={hours}
              idName={nanoid()}
              onChange={onHourChange}
              height={380}
              width={0}
              itemHeight={50}
              selectedID={selectedHour}
              color='#ccc'
              activeColor='#333'
              bgColor='#fff'
            />
            <span className={styles.bottomArrow} onClick={handleHourScrollDown}>
              <ArrowDown />
            </span>
          </div>
          <div className={styles.wheelHourMinWrapper}>
            <span className={styles.arrows} onClick={handleMinScrollUp}>
              <ArrowUp />
            </span>
            <WheelPicker
              ref={minRef}
              data={minutes}
              idName={nanoid()}
              onChange={onMinChange}
              height={380}
              width={0}
              itemHeight={50}
              selectedID={selectedMin}
              color='#ccc'
              activeColor='#333'
              bgColor='#fff'
            />
            <span className={styles.bottomArrow} onClick={handleMinScrollDown}>
              <ArrowDown />
            </span>
          </div>
        </div>
      ),
      [
        handleHourScrollDown,
        handleHourScrollUp,
        handleMinScrollDown,
        handleMinScrollUp,
        onHourChange,
        onMinChange,
        selectedHour,
        selectedMin,
      ]
    );

    return (
      <Tabs
        selectedIndex={isArrivalTab as number}
        className={styles.tabsWrapper}
        onSelect={onTabChange}
      >
        <TabList className={styles.tabList}>
          <Tab
            className={classNames([styles.tab, styles.left])}
            selectedClassName={styles.activeTab}
          >
            <span>Depart After</span>
          </Tab>
          <Tab
            className={classNames([styles.tab, styles.right])}
            selectedClassName={styles.activeTab}
          >
            <span>Arrive By</span>
          </Tab>
        </TabList>

        <TabPanel id='timePickerTabs' className={styles.tabPanel}>
          {scrollPicker}
        </TabPanel>
        <TabPanel className={styles.tabPanel}>{scrollPicker}</TabPanel>
      </Tabs>
    );
  }
);
TimePicker.displayName = 'TimePicker'; // used for debugging

export default TimePicker;
