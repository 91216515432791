import React from 'react';

function Single() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'>
      <circle cx='18' cy='18' r='18' fill='#fff' data-name='Ellipse 162' />
      <path
        fill='#1e1e50'
        d='M.807 5.255L3.649 8.1l.8.807 1.61-1.61-.8-.8-.9-.9h11.463V3.315H4.356l.9-.9.8-.8L4.452 0l-.8.8L.807 3.645 0 4.452l.8.8z'
        transform='rotate(90 6.181 16.27)'
      />
    </svg>
  );
}

export default React.memo(Single);
