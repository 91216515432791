import * as constants from '../actions/constants';
import {
  ActionTypes,
  JourneyResultsState,
  LegSolution,
  Price,
  Results,
} from '../types/journeyResults';

const result: Results = {
  outwardLegSolutions: [],
  outwardCheapest: '',
  returnLegSolutions: [],
  returnCheapest: '',
};
export const initialState: JourneyResultsState = {
  responseStatus: false,
  results: result,
  pickedOutwardPrice: null,
  pickedReturnPrice: null,
  pickedOutwardBookingLegs: null,
  pickedReturnBookingLegs: null,
};

const journeyResults = (
  state: JourneyResultsState = initialState,
  action: ActionTypes
): JourneyResultsState => {
  switch (action.type) {
    case constants.SET_IS_LOADING_JOURNEY_RESULTS:
      return {
        ...state,
        responseStatus: action.data as boolean,
      };
    case constants.SET_JOURNEY_RESULTS:
      return {
        ...state,
        results: action.data as Results,
      };
    case constants.SET_PICKED_OUTBOUND_JOURNEY:
      return {
        ...state,
        pickedOutwardPrice: action.data as Price,
      };
    case constants.SET_PICKED_RETURN_JOURNEY:
      return {
        ...state,
        pickedReturnPrice: action.data as Price,
      };
    case constants.RESET_JOURNEY_RESULTS:
      return {
        ...state,
        results: result,
        pickedOutwardPrice: null,
        pickedReturnPrice: null,
        pickedOutwardBookingLegs: null,
        pickedReturnBookingLegs: null,
        responseStatus: false,
      };
    case constants.SET_PICKED_OUTBOUND_BOOKING_LEG:
      return {
        ...state,
        pickedOutwardBookingLegs: action.data as LegSolution,
      };

    case constants.SET_PICKED_RETURN_BOOKING_LEG:
      return {
        ...state,
        pickedReturnBookingLegs: action.data as LegSolution,
      };

    default:
      return state;
  }
};

export default journeyResults;
