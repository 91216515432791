import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import Info from '../../assets/Info';
import Button from '../Button/Button';
import styles from './FareInfoModal.module.scss';
import { Price } from '../../types/journeyResults';
import { selectCurrentState } from '../../selectors/app';
import { AppGlobalStates } from '../../types/app';
import FaresInformation from '../FareInformation/FareInformation';
import { selectStationByCode } from '../../selectors/stations';
import { Station } from '../../types/journeyPlanner';

interface FareInfoProps {
  price: Price;
  isCollapsed?: boolean;
}

const FaresInfoModal: React.FC<FareInfoProps> = ({
  price,
  isCollapsed = false,
}): React.ReactNode => {
  const [openModal, setOpenModal] = useState(false);
  const currentState = useSelector(selectCurrentState);

  const handleModalOpen = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const deptStation: Station = useSelector(selectStationByCode(price?.origin));
  const arrStation: Station = useSelector(selectStationByCode(price?.destination));

  const stationNames = useMemo(
    () =>
      currentState === AppGlobalStates.OutboundJourneysResult ? (
        <>
          {deptStation?.name} &rarr; {arrStation?.name}
        </>
      ) : (
        <>
          {arrStation?.name} &rarr; {deptStation?.name}
        </>
      ),
    [arrStation, currentState, deptStation]
  );

  return (
    <>
      <div onClick={handleModalOpen} className={styles.iconContainer}>
        {isCollapsed ? 'Fare info' : price?.fareType}
        <Info className={styles.icon} />
      </div>
      <Modal open={openModal}>
        <div className={styles.heading}>Fare information</div>

        <div className={styles.fareInfoModalWrapper}>
          <div className={classNames(styles.fontSize24, styles.flexySpaceBet)}>
            <h4>{price?.fareType}</h4>
            <h4>
              &nbsp;
              {`£${price?.totalAmount}`}
            </h4>
          </div>
          <p>
            <b className={styles.flexySpaceBet}>{stationNames}</b>
          </p>
          <FaresInformation price={price} />
        </div>
        <Button data='Close' onCloseFunc={handleModalOpen} />
      </Modal>
    </>
  );
};

export default FaresInfoModal;
