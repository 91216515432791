//  Version 2.0  for app actions

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { ActionTypes, AppGlobalStates, AppState } from '@/types/app'; // Import your ActionTypes from the appropriate file
// Actions
import { INIT, RESET_HOME, SET_CURRENT_STATE } from './constants';

import { getStations, getPopularStations } from './stations';
import { setResetJourneyPlanner, setResetJourneyTime } from './journeyplanner';
import { setResetJourneyResults } from './journeyResults';
import { getRailcards } from './railcards';

export type ThunkResult<R> = ThunkAction<R, AppState, undefined, ActionTypes>;

export type AppDispatch = ThunkDispatch<AppState, undefined, ActionTypes | CallHistoryMethodAction>;

const appOpenNoServicePage = (): ThunkResult<void> => (dispatch: AppDispatch) => {
  dispatch(push('no-service'));
};

export const appInit =
  (data = false): ThunkResult<void> =>
  (dispatch: AppDispatch) => {
    dispatch({
      type: INIT,
      data,
    });
  };
const onlineListener = (): ThunkResult<void> => (dispatch: AppDispatch) => {
  window.addEventListener('online', () => {
    console.log('App is now online');
    window.location.pathname = '/';
  });

  window.addEventListener('offline', () => {
    console.log('App is now offline');
    dispatch(appOpenNoServicePage());
  });
};

export const appReset = (): ThunkResult<void> => (dispatch: AppDispatch) => {
  dispatch({
    type: RESET_HOME,
    data: null,
  });
};

export const appSetCurrentState =
  (data: AppGlobalStates): ThunkResult<void> =>
  (dispatch: AppDispatch) => {
    dispatch({
      type: SET_CURRENT_STATE,
      data,
    });
  };

export const appInitialize = (): ThunkResult<void> => (dispatch: AppDispatch) => {
  dispatch(appInit(true));
  dispatch(getStations());
  dispatch(getPopularStations());
  dispatch(getRailcards());
  dispatch(onlineListener());
};

export const appOpenHome = (): ThunkResult<void> => (dispatch: AppDispatch) => {
  dispatch(appSetCurrentState(AppGlobalStates.HomePage));
  dispatch(push('/'));
  dispatch(appReset());
  dispatch(setResetJourneyPlanner());
  dispatch(setResetJourneyResults());
};

export const appOpenJourneyPlanner =
  (isReset = false): ThunkResult<void> =>
  (dispatch: AppDispatch) => {
    dispatch(appSetCurrentState(AppGlobalStates.JourneyPlanner));
    dispatch(push('journey-planner'));
    if (isReset) {
      dispatch(setResetJourneyTime());
    }
    dispatch(setResetJourneyResults());
  };

export const appOpenOutboundJourneyResults = (): ThunkResult<void> => (dispatch: AppDispatch) => {
  dispatch(appSetCurrentState(AppGlobalStates.OutboundJourneysResult));
  dispatch(push('journey-results'));
};

export const appOpenReturnJourneyResults = (): ThunkResult<void> => (dispatch: AppDispatch) => {
  dispatch(appSetCurrentState(AppGlobalStates.ReturnJourneysResult));
  dispatch(push('journey-results'));
  // Write code to reset the selected return journey
};

export const appOpenPurchaseSummary = (): ThunkResult<void> => (dispatch: AppDispatch) => {
  dispatch(appSetCurrentState(AppGlobalStates.PurchaseSummary));
  dispatch(push('purchase-summary'));
};
