// This file is a reducer, a simple method that defines
// a initial state for the store and then a switch that
// based on what action is dispatched it will return a brand new state
import * as constants from '../actions/constants';

const initialState = {
  list: [],
};

const railcards = (state = initialState, action: any) => {
  switch (action.type) {
    case constants.SET_RAILCARDS:
      return {
        ...state,
        list: action.railcards,
      };
    default:
      return state;
  }
};

export default railcards;
