import { createSelector } from 'reselect';
import { some, groupBy, map, sumBy } from 'lodash-es';
import {
  selectArrivalStation,
  selectDepartureStation,
  selectOutboundDate,
  selectOutboundIsArrival,
  selectReturnDate,
  selectReturnIsArrival,
} from './journeyPlanner';
import { ArrivalDepart, Station, ToknDate, ToknUTC } from '../types/journeyPlanner';
import {
  JourneyResultsState,
  LegSolution,
  Price,
  Results,
  Ticket,
  FareInfo,
  FaresType,
  PriceNullish,
  LegSolutionNullish,
} from '../types/journeyResults';
import { selectCurrentState } from './app';
import { AppGlobalStates } from '../types/app';
import { selectStations } from './stations';
import { formatMinutesToDuration, parseDurationToMinutes } from '../utils/journeyplanner';

// Do all filter structure json to be populated in UI only here

export const selectJourneyResults = (state: any): JourneyResultsState => state.journeyResults;

export const selectResults = createSelector(
  selectJourneyResults,
  ({ results }): Results => results
);

export const selectAllOutwardLegSolutions = createSelector(
  selectResults,
  ({ outwardLegSolutions }): LegSolution[] => outwardLegSolutions
);

export const selectOutwardCheapestFare = createSelector(
  selectResults,
  ({ outwardCheapest }): string => outwardCheapest
);

export const selectOutwardLegSolutionsLength = createSelector(
  selectResults,
  ({ outwardLegSolutions }): number => outwardLegSolutions.length
);

export const selectAllReturnLegSolutions = createSelector(
  selectResults,
  ({ returnLegSolutions }): LegSolution[] => returnLegSolutions
);

export const selectReturnCheapestFare = createSelector(
  selectResults,
  ({ returnCheapest }): string => returnCheapest
);

export const selectReturnLegSolutionLength = createSelector(
  selectResults,
  ({ returnLegSolutions }): number => returnLegSolutions.length
);

export const selectIsReturnLegSolutionAvailable = createSelector(
  selectReturnLegSolutionLength,
  (LegSolutionsLength): boolean => LegSolutionsLength !== 0
);

export const selectLoadingState = createSelector(
  selectJourneyResults,
  (journeyResult): boolean => journeyResult.responseStatus
);

export const selectPickedOutwardBookingLeg = createSelector(
  selectJourneyResults,
  ({ pickedOutwardBookingLegs }): LegSolutionNullish => pickedOutwardBookingLegs
);

export const selectPickedReturnBookingLeg = createSelector(
  selectJourneyResults,
  ({ pickedReturnBookingLegs }): LegSolutionNullish => pickedReturnBookingLegs
);

export const selectPickedOutwardPrice = createSelector(
  selectJourneyResults,
  ({ pickedOutwardPrice }): PriceNullish => pickedOutwardPrice
);

export const selectPickedReturnPrice = createSelector(
  selectJourneyResults,
  ({ pickedReturnPrice }): PriceNullish => pickedReturnPrice
);

export const selectLastOutboundLegSolution = createSelector(
  selectOutwardLegSolutionsLength,
  selectAllOutwardLegSolutions,
  (outwardLegLength: number, outwardLegs: LegSolution[]): LegSolution =>
    outwardLegs[outwardLegLength - 1]
);

export const selectLastReturnLegSolution = createSelector(
  selectReturnLegSolutionLength,
  selectAllReturnLegSolutions,
  (returnLegLength: number, returnLegs: LegSolution[]): LegSolution =>
    returnLegs[returnLegLength - 1]
);

export const selectFirstOutwardLegSolution = createSelector(
  selectAllOutwardLegSolutions,
  (outwardLegs: LegSolution[]): LegSolution => outwardLegs[0]
);

export const selectFirstReturnLegSolution = createSelector(
  selectAllReturnLegSolutions,
  (returnLegs: LegSolution[]): LegSolution => returnLegs[0]
);

export const selectFirstOutwardLegSolutionUTC = createSelector(
  selectFirstOutwardLegSolution,
  selectOutboundIsArrival,
  (outwardLegSolution: LegSolution, isArrival: ArrivalDepart): ToknUTC =>
    isArrival === ArrivalDepart.DEPARTURE
      ? outwardLegSolution?.departureTime?.adjustedTime
      : outwardLegSolution?.arrivalTime?.adjustedTime
);

export const selectLastOutwardLegSolutionUTC = createSelector(
  selectLastOutboundLegSolution,
  selectOutboundIsArrival,
  (outwardLegSolution: LegSolution, isArrival: ArrivalDepart): ToknUTC =>
    isArrival === ArrivalDepart.DEPARTURE
      ? outwardLegSolution?.departureTime?.adjustedTime
      : outwardLegSolution?.arrivalTime?.adjustedTime
);

export const selectFirstReturnLegSolutionUTC = createSelector(
  selectFirstReturnLegSolution,
  selectReturnIsArrival,
  (returnLegSolution: LegSolution, isArrival: ArrivalDepart): ToknUTC =>
    isArrival === ArrivalDepart.DEPARTURE
      ? returnLegSolution?.departureTime?.adjustedTime
      : returnLegSolution?.arrivalTime?.adjustedTime
);

export const selectLastReturnLegSolutionUTC = createSelector(
  selectLastReturnLegSolution,
  selectReturnIsArrival,
  (returnLegSolution: LegSolution, isArrival: ArrivalDepart): ToknUTC =>
    isArrival === ArrivalDepart.DEPARTURE
      ? returnLegSolution?.departureTime?.adjustedTime
      : returnLegSolution?.arrivalTime?.adjustedTime
);

export const selectLegSolutions = createSelector(
  selectCurrentState,
  selectAllOutwardLegSolutions,
  selectAllReturnLegSolutions,
  (currentState, allOutwardLegSolutions, allReturnLegSolutions): LegSolution[] => {
    switch (currentState) {
      case AppGlobalStates.OutboundJourneysResult:
        return allOutwardLegSolutions;
      case AppGlobalStates.ReturnJourneysResult:
        return allReturnLegSolutions;
      default:
        return [];
    }
  }
);

export const selectLegSolutionFromIndex = (legSolutionIndex: number) =>
  createSelector(selectLegSolutions, (legSolution: LegSolution[]): LegSolution | null => {
    if (legSolution.length === 0) return null;
    return legSolution[legSolutionIndex];
  });

export const selectFromStation = createSelector(
  selectCurrentState,
  selectDepartureStation,
  selectArrivalStation,
  (currentState, departureStation, arrivalStation): Station => {
    switch (currentState) {
      case AppGlobalStates.OutboundJourneysResult:
        return departureStation;
      case AppGlobalStates.ReturnJourneysResult:
        return arrivalStation;
      default:
        return departureStation;
    }
  }
);

export const selectToStation = createSelector(
  selectCurrentState,
  selectArrivalStation,
  selectDepartureStation,
  (currentState, arrivalStation, departureStation): Station => {
    switch (currentState) {
      case AppGlobalStates.OutboundJourneysResult:
        return arrivalStation;
      case AppGlobalStates.ReturnJourneysResult:
        return departureStation;
      default:
        return arrivalStation;
    }
  }
);

export const selectDateTime = createSelector(
  selectCurrentState,
  selectOutboundDate,
  selectReturnDate,
  (currentState, outboundDate, returnDate): ToknDate => {
    switch (currentState) {
      case AppGlobalStates.OutboundJourneysResult:
        return outboundDate;
      case AppGlobalStates.ReturnJourneysResult:
        return returnDate;
      default:
        return outboundDate;
    }
  }
);

export const selectCheapestFareAmount = createSelector(
  selectCurrentState,
  selectOutwardCheapestFare,
  selectReturnCheapestFare,
  (currentState, outboundCheapest, returnCheapest): string => {
    switch (currentState) {
      case AppGlobalStates.OutboundJourneysResult:
        return outboundCheapest;
      case AppGlobalStates.ReturnJourneysResult:
        return returnCheapest;
      default:
        return outboundCheapest;
    }
  }
);
export const selectAreAllETicketsAvailable = createSelector(
  selectLegSolutions,
  (legSolutions: LegSolution[]): boolean => {
    if (legSolutions.length === 0) return true;
    for (const legSolution of legSolutions) {
      const { prices } = legSolution;
      const areAllETicketsAvailable = some(prices, ({ eticketCollection }) => eticketCollection);
      if (areAllETicketsAvailable) {
        return true;
      }
    }
    return false;
  }
);

export const selectIsETicketAvailable = (legSolutionIndex: number) =>
  createSelector(selectLegSolutions, (legSolutions: LegSolution[]): boolean => {
    const { prices }: LegSolution = legSolutions[legSolutionIndex];
    return some(prices, ({ eticketCollection }) => eticketCollection);
  });

export const selectCheapestFareType = (legSolutionIndex: number) =>
  createSelector(
    selectCurrentState,
    selectLegSolutions,
    (currentState: AppGlobalStates, legSolutions: LegSolution[]): FaresType => {
      const { cheapest } = legSolutions[legSolutionIndex];

      switch (currentState) {
        case AppGlobalStates.OutboundJourneysResult:
          if (cheapest[FaresType.OutwardSingle] !== undefined) {
            return FaresType.OutwardSingle;
          }
          return FaresType.Return;

        case AppGlobalStates.ReturnJourneysResult:
          return FaresType.InboundSingle;
        default:
          return FaresType.OutwardSingle;
      }
    }
  );

export const selectCheapestFare = (legSolutionIndex: number) =>
  createSelector(
    selectLegSolutions,
    selectCheapestFareType(legSolutionIndex),
    (legSolutions: LegSolution[], cheapestFareType: FaresType): Price | undefined => {
      const { cheapest } = legSolutions[legSolutionIndex];
      return cheapest?.[cheapestFareType]?.[0];
    }
  );

export const selectAlternativeDestination = (price: Price): any =>
  createSelector(selectStations, (stationList) => {
    const alternativeDestinationArray = price?.alternateDestinations;

    if (!alternativeDestinationArray || !Array.isArray(alternativeDestinationArray)) {
      return [];
    }
    const stations = alternativeDestinationArray.map((destination) =>
      stationList.find((item: Station) => item.nlc === destination)
    );

    return stations;
  });

export const selectAlternativeOrigins = (price: Price): any =>
  createSelector(selectStations, (stationList) => {
    const alternativeDestinationArray = price?.alternateOrigins;

    if (!alternativeDestinationArray || !Array.isArray(alternativeDestinationArray)) {
      return [];
    }
    const stations = alternativeDestinationArray.map((destination) =>
      stationList.find((item: Station) => item.nlc === destination)
    );

    return stations;
  });

export const selectGroupTicketsWithRailcardInfo = (tickets: Ticket[]): any =>
  createSelector((): FareInfo[] => {
    const groupedByRailcard = groupBy(tickets, (ticket) =>
      ticket.railcard ? ticket.railcard.name : ''
    );

    const result = map(groupedByRailcard, (group, railcard) => ({
      adult: sumBy(group, 'adults'),
      children: sumBy(group, 'children'),
      adultPrice: sumBy(group, (ticket) =>
        ticket.adults === 1 ? parseFloat(ticket.price) : 0
      ).toFixed(2),
      childrenPrice: sumBy(group, (ticket) =>
        ticket.children === 1 ? parseFloat(ticket.price) : 0
      ).toFixed(2),
      railcard,
    }));

    return result;
  });

export const selectAnyTicketHasGroupSave = (tickets: Ticket[]): any =>
  createSelector((): boolean => {
    const hasGroupSaveTicket = tickets?.some((ticket) => ticket.railcard?.code === 'GS3');
    return hasGroupSaveTicket;
  });

export const selectAnyTicketHasRailcards = (tickets: Ticket[]): any =>
  createSelector((): boolean => {
    const hasGroupSaveTicket = tickets?.some(
      (ticket) => ticket.railcard?.code !== 'GS3' && ticket.railcard !== null
    );
    return hasGroupSaveTicket;
  });

export const selectFastestService = createSelector(
  selectCurrentState,
  selectAllOutwardLegSolutions,
  selectAllReturnLegSolutions,
  (currentState, allOutwardLegSolutions, allReturnLegSolutions): string => {
    let legSolutions: LegSolution[] = [];
    switch (currentState) {
      case AppGlobalStates.OutboundJourneysResult:
        legSolutions = allOutwardLegSolutions;
        break;
      case AppGlobalStates.ReturnJourneysResult:
        legSolutions = allReturnLegSolutions;
        break;
    }

    if (legSolutions !== undefined && legSolutions.length > 0) {
      const minDuration = legSolutions.reduce((min, solution) => {
        const durationInMinutes = parseDurationToMinutes(solution.duration);
        return durationInMinutes < min ? durationInMinutes : min;
      }, Infinity);
      return formatMinutesToDuration(minDuration);
    }
    return '';
  }
);
