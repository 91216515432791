// This file is going to replace journeplanner.js file
// Working in Progress

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateTime, ToknDate, ToknTime, ToknUTC } from '../types/journeyPlanner';

dayjs.extend(utc);
dayjs.extend(timezone);

export const toknDate = (dayJs: dayjs.Dayjs): ToknDate => ({
  currDateISO: dayJs.format('YYYY-MM-DDTHH:mm:ss'),
  day: dayJs.date(),
  month: dayJs.month(),
  monthString: dayJs.format('MM'),
  monthName: dayJs.format('MMMM'),
  monthShortName: dayJs.format('MMM'),
  year: dayJs.year(),
  date: dayJs.format('YYYY-MM-DD'),
  dayName: dayJs.format('dddd'),
  dayShortName: dayJs.format('ddd'),
  dayString: dayJs.format('DD'),
  expression: dayJs.isSame(dayjs().tz('Europe/London'), 'day')
    ? 'Today'
    : dayJs.format('ddd DD MMM'),
});

export const generateUTC = ({ date }: { date: string }, { time }: { time: string }): string =>
  dayjs(`${date} ${time}+00:00`).utc().format('YYYY-MM-DDTHH:mm:ss');

export const toknTime = (dayJs: dayjs.Dayjs): ToknTime => {
  const hour = String(dayJs.hour()).padStart(2, '0');
  const minute = String(dayJs.minute()).padStart(2, '0');
  const currentTime = new Date();
  const currentDayJs = dayjs(currentTime).tz('Europe/London');
  const isSameDay = currentDayJs.isSame(dayJs, 'day') && currentTime.getHours() === dayJs.hour();

  const time = `${hour}:${minute}`;
  const expression = isSameDay ? 'Now' : time;

  return {
    hour: dayJs.hour(),
    minute: dayJs.minute(),
    time,
    expression,
  };
};

export const getCurrentDate = ({
  minOffset = 0,
  hourOffset = 0,
  utcCurrent = null,
}: {
  minOffset?: number;
  hourOffset?: number;
  utcCurrent?: string | null;
}): DateTime => {
  let currDayJs = dayjs(utcCurrent ?? undefined).tz('Europe/London');

  if (minOffset !== 0) {
    const currentMinutes = currDayJs.minute();
    if (minOffset > 0) {
      const additionalMinutes = 15 - (currentMinutes % 15); // Rounding up to the next 15-minute interval
      currDayJs = currDayJs.add(additionalMinutes, 'minute');
    } else {
      const subtractedMinutes = currentMinutes % 15; // Rounding down to the previous 15-minute interval
      if (subtractedMinutes > 0) {
        currDayJs = currDayJs.subtract(subtractedMinutes, 'minute');
      }
    }
  }

  if (hourOffset !== 0) {
    currDayJs =
      hourOffset > 0
        ? currDayJs.add(hourOffset, 'hour')
        : currDayJs.subtract(Math.abs(hourOffset), 'hour');
  }
  const date: ToknDate = toknDate(currDayJs);
  const time: ToknTime = toknTime(currDayJs);

  return {
    date,
    time,
    utc: generateUTC(date, time),
  };
};

export const parseDuration = (duration: string) => {
  const hoursMatch = duration.match(/(\d+)H/);
  const minutesMatch = duration.match(/(\d+)M/);
  const hours = hoursMatch ? `${hoursMatch[1]}h` : '';
  const minutes = minutesMatch ? `${minutesMatch[1]}m` : '';
  return `${hours} ${minutes}`;
};

export const parseHourTimeFromUTC = (utc: ToknUTC): string => dayjs(utc).format('HH:mm');

export const parseDateFromUTC = (utc: ToknUTC): string => dayjs.utc(utc).format('dddd D MMMM YYYY');

export function parseDurationToMinutes(duration: string) {
  const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
  const matches = duration.match(regex);
  const hours = matches && matches[1] ? parseInt(matches[1], 10) * 60 : 0;
  const minutes = matches && matches[2] ? parseInt(matches[2], 10) : 0;
  return hours + minutes;
}

export function formatMinutesToDuration(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `PT${hours ? `${hours}H` : ''}${remainingMinutes ? `${remainingMinutes}M` : ''}`;
}
