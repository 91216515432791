import { createSelector } from 'reselect';
import { State } from '@/types/app';

export const selectStations = (state: State) => state.stations.list || [];

export const selectMainStations = createSelector(selectStations, (stations) =>
  stations.filter((item) => item.crs)
);

export const selectStationByCode = (code: string) =>
  createSelector(selectStations, (stations) => stations.find((item) => item.nlc === code));

// export const selectStationByCode = (code: string): Station | undefined =>
//   createSelector(selectStations, (stations) => stations.find((item) => item.nlc === code));

export const selectPopularStations = (state: State) => state.stations.popular.slice(0, 14) || [];
// UI shows top 14 stations
