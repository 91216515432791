import { Accessibility } from './accessibility';
import { JourneyPlannerState } from './journeyPlanner';
import { JourneyResultsState } from './journeyResults';
import { RailcardState } from './railcard';
import { StationState } from './stations';

export type ApiCredentials = {
  client_id: string;
  client_secret: string;
  audience: string;
  grant_type: string;
};

export type ApiToken = {
  access_token: string;
  token_type: string;
};

export type ApiConfig = {
  credentials: ApiCredentials;
  url: string;
  authUrl: string;
  token: ApiToken;
};

export type ModalState = boolean;

export type AppState = {
  ready: boolean;
  environment: string;
  modal: ModalState;
  currentState: AppGlobalStates;
  accessibility: Accessibility;
};

export type ActionTypes = {
  type: string;
  data:
    | ModalState
    | ApiConfig
    | ApiToken
    | ApiCredentials
    | null
    | AppGlobalStates
    | Accessibility
    | string;
};

export type State = {
  app: AppState;
  journeyPlanner: JourneyPlannerState;
  journeyResults: JourneyResultsState;
  stations: StationState;
  railcards: RailcardState;
};

export enum AppGlobalStates {
  Default = 0,
  HomePage = 1,
  JourneyPlanner = 2,
  OutboundDateTimePicker = 3,
  ReturnDateTimePicker = 4,
  PassengerPicker = 5,
  RailcardPicker = 6,
  OutboundJourneysResult = 7,
  ReturnJourneysResult = 8,
  PurchaseSummary = 9,
  StationPicker = 10,
}

export type GetState = () => State;

export type StringNullify = string | null;
export type StringUndefined = string | undefined;
export type StringNullifyUndefined = string | null | undefined;
