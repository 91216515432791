import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Datepicker.module.scss';

import ToknCalendar, { ToknCalendarRef } from '../../Calendar/Calendar';

import TimePicker, { TimePickerRef } from '../../TimePicker/TimePicker';
import Modal from '../../Modal/Modal';

import Button from '../../Button/Button';
import EditBox from '../../EditBox/EditBox';

import { DateTime, ToknDate, ToknTime, ToknUTC } from '../../../types/journeyPlanner';
import { AppGlobalStates, ModalState } from '../../../types/app';
import { generateUTC } from '../../../utils/journeyplanner';
import {
  selectOutboundDateTimeExpression,
  selectOutboundDateTimeUTC,
  selectOutboundIsArrival,
  selectOutboundTime,
  selectReturnDateTimeExpression,
  selectReturnDateTimeUTC,
  selectReturnIsArrival,
  selectReturnTime,
  selectStartUTCThreshold,
} from '../../../selectors/journeyPlanner';
import { AppDispatch, appSetCurrentState } from '../../../actions/app';
import {
  JourneyPlannerDispatch,
  setSelectedDateTime,
  setSelectedIsArrival,
} from '../../../actions/journeyplanner';

interface DatePickerProps {
  state: AppGlobalStates;
  label: string;
}

const DatePicker: React.FC<DatePickerProps> = ({ state, label }) => {
  const dispatch = useDispatch<JourneyPlannerDispatch>();
  const AppDispatch = useDispatch<AppDispatch>();
  const [dateTimeModalOpen, setDateTimeModalOpen] = useState<ModalState>(false);

  const placeholderExpression = useSelector(
    state === AppGlobalStates.OutboundDateTimePicker
      ? selectOutboundDateTimeExpression
      : selectReturnDateTimeExpression
  );
  const isArrival = useSelector(
    state === AppGlobalStates.OutboundDateTimePicker
      ? selectOutboundIsArrival
      : selectReturnIsArrival
  );

  const travelTime: ToknTime = useSelector(
    state === AppGlobalStates.OutboundDateTimePicker ? selectOutboundTime : selectReturnTime
  );

  const dateTimeUTC: ToknUTC = useSelector(
    state === AppGlobalStates.OutboundDateTimePicker
      ? selectOutboundDateTimeUTC
      : selectReturnDateTimeUTC
  );

  const title: string = state === AppGlobalStates.OutboundDateTimePicker ? 'outbound' : 'return';

  const startUTCThreshold: ToknUTC = useSelector(selectStartUTCThreshold);

  const dayPickerRef = useRef<ToknCalendarRef>();
  const timePickerRef = useRef<TimePickerRef>();

  useEffect(() => {
    if (dateTimeModalOpen) {
      AppDispatch(appSetCurrentState(state));
    } else {
      AppDispatch(appSetCurrentState(AppGlobalStates.JourneyPlanner));
    }
  }, [AppDispatch, dateTimeModalOpen, state]);

  const handleCloseModal = useCallback(() => {
    setDateTimeModalOpen(false);
  }, []);

  const handleDateTimeSelection = useCallback(() => {
    const [time, arrival] = timePickerRef.current.getToknTime();
    const date: ToknDate = dayPickerRef.current.getToknDate();
    if (time != null) {
      const utc: ToknUTC = generateUTC(date, time);
      const dateTime: DateTime = {
        date,
        time,
        utc,
      };
      dispatch(setSelectedDateTime(dateTime, arrival));
      dispatch(setSelectedIsArrival(arrival));
      setDateTimeModalOpen(false);
    }
  }, [dispatch]);

  return (
    <>
      <EditBox
        label={label}
        placeHolder={placeholderExpression}
        onClickCallback={() => setDateTimeModalOpen(true)}
        iconName='date'
      />
      <Modal open={dateTimeModalOpen}>
        <>
          <div className={styles.header}>Select {title} date time </div>
          {dateTimeModalOpen && (
            <div className={styles.calendarTimeWrapper}>
              <div className={styles.datePicker}>
                <ToknCalendar
                  date={dateTimeUTC}
                  timeRef={timePickerRef}
                  ref={dayPickerRef}
                  startDate={startUTCThreshold}
                />
              </div>
              <TimePicker
                ref={timePickerRef}
                hour={travelTime.hour.toString()}
                minute={travelTime.minute.toString()}
                startDate={startUTCThreshold}
                arrival={isArrival}
              />
            </div>
          )}

          <Button
            data='Select'
            onSelectFunc={handleDateTimeSelection}
            onCloseFunc={handleCloseModal}
          />
        </>
      </Modal>
    </>
  );
};

export default DatePicker;
