import { BookedJourney } from '../types/bookings';

export const parseBookedSeats = (bookingJourney: BookedJourney): string => {
  if (bookingJourney) {
    const leg = bookingJourney?.legs?.legs;
    const filteredLegs = leg?.filter((legItr) => legItr.reservable === 'Compulsory');
    if (filteredLegs?.length > 0) {
      const formattedSeats = filteredLegs
        .map((legMap) => legMap.seats.map((seat) => ` ${seat.coach} ${seat.seat} `))
        .flat();
      return `Booked Seats : ${formattedSeats}`;
    }
  }
  return '';
};

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
