import { CallHistoryMethodAction } from 'connected-react-router';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import dayjs from 'dayjs';
import {
  SET_DEPARTURE_STATION,
  RESET_JOURNEY_PLANNER,
  SET_TRAVEL_VIA_VOID,
  SET_IS_TRAVEL_VIA_OR_AVOID,
  SET_TRAVEL_VIA_AVOID_STATION,
  SET_JOURNEY_TYPE,
  SET_RETURN_DATE_TIME,
  SET_ARRIVAL_STATION,
  SET_PASSENGER_COUNT,
  SET_SELECTED_RAILCARD,
  SET_OUTBOUND_DATE_TIME,
  SET_OUTBOUND_IS_ARRIVAL,
  SET_RETURN_IS_ARRIVAL,
  SET_OUTBOUND_START_UTC,
  SET_OUTBOUND_END_UTC,
  SET_RETURN_END_UTC,
  SET_RETURN_START_UTC,
} from './constants';
import {
  ActionTypes,
  ArrivalDepart,
  DateTime,
  JourneyPlannerState,
  Passengers,
  Railcards,
  Station,
  ToknUTC,
} from '@/types/journeyPlanner';
import { getCurrentDate } from '@/utils/journeyplanner';

import { getJourneyResults, setResetJourneyResults } from './journeyResults';
import {
  selectFirstOutwardLegSolutionUTC,
  selectFirstReturnLegSolutionUTC,
  selectLastOutwardLegSolutionUTC,
  selectLastReturnLegSolutionUTC,
} from '@/selectors/journeyResults';
import {
  selectOutboundEndUTC,
  selectOutboundStartUTC,
  selectReturnEndUTC,
  selectReturnStartUTC,
} from '@/selectors/journeyPlanner';
import { AppGlobalStates } from '@/types/app';
import { selectCurrentState } from '@/selectors/app';

export type ThunkResult<R> = ThunkAction<R, JourneyPlannerState, undefined, ActionTypes>;

export type JourneyPlannerDispatch = ThunkDispatch<
  JourneyPlannerState,
  undefined,
  ActionTypes | CallHistoryMethodAction
>;
export const setDepartureStation =
  (data: Station): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    dispatch({
      type: SET_DEPARTURE_STATION,
      data,
    });
  };

export const setArrivalStation =
  (data: Station): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    dispatch({
      type: SET_ARRIVAL_STATION,
      data,
    });
  };

export const setTravelViaAvoidStation =
  (data: Station): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    dispatch({
      type: SET_TRAVEL_VIA_AVOID_STATION,
      data,
    });
  };
export const setEnableTravelViaAvoid =
  (data: boolean): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    dispatch({
      type: SET_TRAVEL_VIA_VOID,
      data,
    });
  };

export const setIsTravelViaOrAvoid =
  (data: any): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    dispatch({
      type: SET_IS_TRAVEL_VIA_OR_AVOID,
      data,
    });
  };

export const setJourneyType =
  (data: any): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    dispatch({
      type: SET_JOURNEY_TYPE,
      data,
    });
  };

export const setPassengerCount =
  (data: Passengers): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    dispatch({
      type: SET_PASSENGER_COUNT,
      data,
    });
  };

export const setSelectedRailcard =
  (data: Railcards): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    dispatch({
      type: SET_SELECTED_RAILCARD,
      data,
    });
  };

export const setSelectedOutboundIsArrival =
  (data: ArrivalDepart): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    dispatch({
      type: SET_OUTBOUND_IS_ARRIVAL,
      data,
    });
  };

export const setSelectedReturnIsArrival =
  (data: ArrivalDepart): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    dispatch({
      type: SET_RETURN_IS_ARRIVAL,
      data,
    });
  };

export const setResetJourneyPlanner = (): any => (dispatch: JourneyPlannerDispatch) => {
  dispatch({
    type: RESET_JOURNEY_PLANNER,
    data: null,
  });
};

// Helper function to handle the common logic for setting date and time
const handleDateTime = (
  dispatch: JourneyPlannerDispatch,
  data: DateTime,
  isArrival: ArrivalDepart,
  setDateTimeType: string,
  setStartUTCType: string,
  setEndUTCType: string
) => {
  dispatch({ type: setDateTimeType, data });

  if (isArrival === ArrivalDepart.ARRIVAL) {
    dispatch({ type: setEndUTCType, data: data?.utc });
    const { utc: endUTC }: DateTime = getCurrentDate({
      utcCurrent: data?.utc,
      hourOffset: -3,
    });
    dispatch({ type: setStartUTCType, data: endUTC });
  } else {
    dispatch({ type: setStartUTCType, data: data?.utc });
    const { utc: endUTC }: DateTime = getCurrentDate({
      utcCurrent: data?.utc,
      hourOffset: 3,
    });
    dispatch({ type: setEndUTCType, data: endUTC });
  }
};

export const setSelectedReturnDateTime =
  (data: DateTime, isArrival: ArrivalDepart = ArrivalDepart.DEPARTURE): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    handleDateTime(
      dispatch,
      data,
      isArrival,
      SET_RETURN_DATE_TIME,
      SET_RETURN_START_UTC,
      SET_RETURN_END_UTC
    );
  };

const dispatchReturnDateTimeFromUTC =
  (utc: string): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    const returnDateTime: DateTime = getCurrentDate({
      hourOffset: 8,
      utcCurrent: utc,
    });
    dispatch(setSelectedReturnDateTime(returnDateTime));
  };

export const setSelectedOutboundDateTime =
  (
    data: DateTime,
    updateReturnDateTime?: boolean,
    isArrival: ArrivalDepart = ArrivalDepart.DEPARTURE
  ): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch) => {
    handleDateTime(
      dispatch,
      data,
      isArrival,
      SET_OUTBOUND_DATE_TIME,
      SET_OUTBOUND_START_UTC,
      SET_OUTBOUND_END_UTC
    );
    if (updateReturnDateTime) {
      dispatch(dispatchReturnDateTimeFromUTC(data?.utc));
    }
  };

const setEarlierJourneys =
  (
    isEarlier: boolean,
    selectFirstJourneyUTC: (args: JourneyPlannerState) => ToknUTC,
    selectStartUTC: (args: JourneyPlannerState) => ToknUTC,
    selectLastJourneyUTC: (args: JourneyPlannerState) => ToknUTC,
    selectEndUTC: (args: JourneyPlannerState) => ToknUTC,
    setStartUTC: string,
    setEndUTC: string,
    setDateTime: string,
    setIsArrival: string,
    arrivalDepart: ArrivalDepart
  ): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch, getState) => {
    let isEarlierDateInvalid = false;

    const currentDate = dayjs(undefined).tz('Europe/London');

    if (isEarlier) {
      dispatch({
        type: setIsArrival,
        data: arrivalDepart,
      });

      let startJourneyTime: ToknUTC = selectFirstJourneyUTC(getState());
      if (startJourneyTime === undefined) {
        startJourneyTime = selectStartUTC(getState());
      }
      const newStartDateTime: DateTime = getCurrentDate({
        hourOffset: -3,
        utcCurrent: startJourneyTime,
      });

      const inputDate = dayjs(newStartDateTime?.utc).tz('Europe/London');

      const isDateValid = inputDate.isAfter(currentDate);

      if (isDateValid) {
        dispatch({
          type: setStartUTC,
          data: newStartDateTime?.utc,
        });
        dispatch({
          type: setEndUTC,
          data: startJourneyTime,
        });

        const dateTime: DateTime = getCurrentDate({
          minOffset: -5,
          utcCurrent: newStartDateTime?.utc,
        });

        dispatch({
          type: setDateTime,
          data: dateTime,
        });
        console.log('Earlier is planned good Now');
      } else {
        isEarlierDateInvalid = true;
      }
    }

    if (isEarlierDateInvalid || !isEarlier) {
      dispatch({
        type: setIsArrival,
        data: ArrivalDepart.DEPARTURE,
      });

      let endJourneyTime: ToknUTC = isEarlierDateInvalid
        ? currentDate.utc().format('YYYY-MM-DDTHH:mm:ss')
        : selectLastJourneyUTC(getState());

      if (endJourneyTime === undefined) {
        endJourneyTime = selectEndUTC(getState());
      }
      const newEndDateTimeUTC: DateTime = getCurrentDate({
        hourOffset: 3,
        utcCurrent: endJourneyTime,
      });

      dispatch({
        type: setStartUTC,
        data: endJourneyTime,
      });
      dispatch({
        type: setEndUTC,
        data: newEndDateTimeUTC?.utc,
      });

      const dateTime: DateTime = getCurrentDate({
        minOffset: -5,
        utcCurrent: endJourneyTime,
      });

      dispatch({
        type: setDateTime,
        data: dateTime,
      });
    }
    dispatch(setResetJourneyResults());

    dispatch(getJourneyResults());
  };

export const setOutwardEarlierJourneys = (isEarlier: boolean): ThunkResult<void> =>
  setEarlierJourneys(
    isEarlier,
    selectFirstOutwardLegSolutionUTC,
    selectOutboundStartUTC,
    selectLastOutwardLegSolutionUTC,
    selectOutboundEndUTC,
    SET_OUTBOUND_START_UTC,
    SET_OUTBOUND_END_UTC,
    SET_OUTBOUND_DATE_TIME,
    SET_OUTBOUND_IS_ARRIVAL,
    ArrivalDepart.ARRIVAL
  );

export const setReturnEarlierJourneys = (isEarlier: boolean): ThunkResult<void> =>
  setEarlierJourneys(
    isEarlier,
    selectFirstReturnLegSolutionUTC,
    selectReturnStartUTC,
    selectLastReturnLegSolutionUTC,
    selectReturnEndUTC,
    SET_RETURN_START_UTC,
    SET_RETURN_END_UTC,
    SET_RETURN_DATE_TIME,
    SET_RETURN_IS_ARRIVAL,
    ArrivalDepart.ARRIVAL
  );

export const setResetJourneyTime = (): any => (dispatch: JourneyPlannerDispatch) => {
  const outboundDateTime: DateTime = getCurrentDate({ minOffset: 5 });

  dispatch(setSelectedOutboundDateTime(outboundDateTime, true));

  dispatch({
    type: SET_OUTBOUND_IS_ARRIVAL,
    data: ArrivalDepart.DEPARTURE,
  });

  dispatch({
    type: SET_RETURN_IS_ARRIVAL,
    data: ArrivalDepart.DEPARTURE,
  });
};

export const setSelectedIsArrival =
  (data: ArrivalDepart): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch, getState) => {
    const state = selectCurrentState(getState());

    switch (state) {
      case AppGlobalStates.OutboundDateTimePicker:
        dispatch({
          type: SET_OUTBOUND_IS_ARRIVAL,
          data,
        });
        break;
      case AppGlobalStates.ReturnDateTimePicker:
        dispatch({
          type: SET_RETURN_IS_ARRIVAL,
          data,
        });
        break;
      default:
        break;
    }
  };

export const setSelectedDateTime =
  (dateTime: DateTime, isArrival: ArrivalDepart): ThunkResult<void> =>
  (dispatch: JourneyPlannerDispatch, getState) => {
    const state = selectCurrentState(getState());

    switch (state) {
      case AppGlobalStates.OutboundDateTimePicker:
        dispatch(setSelectedOutboundDateTime(dateTime, true, isArrival));
        break;
      case AppGlobalStates.ReturnDateTimePicker:
        dispatch(setSelectedReturnDateTime(dateTime, isArrival));
        break;
      default:
        break;
    }
  };
