import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PassengerPicker, { PassengerPickerRef } from '../../PassengerPicker/PassengerPicker';
import Modal from '../../Modal/Modal';
import EditBox from '../../EditBox/EditBox';
import Button from '../../Button/Button';
import { selectPassengerExpression, selectRailcardNames } from '../../../selectors/journeyPlanner';
import { Passengers, Railcards } from '../../../types/journeyPlanner';
import styles from './PassengerRailcardPicker.module.scss';
import {
  JourneyPlannerDispatch,
  setPassengerCount,
  setSelectedRailcard,
} from '../../../actions/journeyplanner';
// const random = require("random-key");
// https://react-component.github.io/dropdown/examples/context-menu.html

import RailcardSelector, { RailCardPickerRef } from '../../RailcardSelector/RailcardSelector';

function PassengerRailcardPicker(): React.ReactElement {
  const [openModal, setOpenModal] = useState(false);

  const passengerPickerRef = useRef<PassengerPickerRef | null>(null);
  const railcardPickerRef = useRef<RailCardPickerRef | null>(null);

  const passengerExpression: string = useSelector(selectPassengerExpression);
  const railcardExpression: string = useSelector(selectRailcardNames);

  const dispatch = useDispatch<JourneyPlannerDispatch>();

  const handleSelection = useCallback(() => {
    const passTotal = passengerPickerRef?.current?.getTotalCount() as number;

    if (passTotal === 0 || passTotal < (railcardPickerRef?.current?.getTotalCount() as number)) {
      return;
    }

    const passengerCount: Passengers = passengerPickerRef?.current?.getPassengers() as Passengers;
    const railcardSelected: Railcards =
      railcardPickerRef?.current?.getSelectedRailCards() as Railcards;
    const isValid = Object.values(railcardSelected).every((val) => val.code !== '');
    if (!isValid) {
      return;
    }
    setOpenModal(false);
    dispatch(setPassengerCount(passengerCount));
    dispatch(setSelectedRailcard(railcardSelected));

    console.log(passengerCount);
    console.log(railcardSelected);
  }, [dispatch]);

  const handleClose = useCallback(() => {
    setOpenModal(false);
    passengerPickerRef?.current?.resetPrevState();
    railcardPickerRef?.current?.resetPrevState();
  }, []);

  return (
    <>
      <EditBox
        onClickCallback={() => setOpenModal(true)}
        label='Passenger :'
        iconName='passengers'
        placeHolder={passengerExpression}
      />

      <EditBox
        onClickCallback={() => setOpenModal(true)}
        label='Railcard :'
        iconName='railcard'
        placeHolder={railcardExpression}
      />

      <Modal open={openModal}>
        <div className={styles.header}>Select Passenger & Railcard</div>

        <div className={styles.pickerWrapper}>
          <PassengerPicker ref={passengerPickerRef} forwardedRef={railcardPickerRef} />
          <hr className={styles.horizontalLine} />
          <RailcardSelector ref={railcardPickerRef} />
        </div>
        <Button data='Ok' onSelectFunc={handleSelection} onCloseFunc={handleClose} />
        {/* </div> */}
      </Modal>
    </>
  );
}

export default React.memo(PassengerRailcardPicker);
