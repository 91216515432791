import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJSON from './lang/en.json';
import ptJSON from './lang/pt.json';

i18n.use(initReactI18next).init({
  resources: {
    EN: { ...enJSON },
    PT: { ...ptJSON },
  },
  lng: 'EN',
});
