import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './JourneyButton.module.scss';
import { selectRailcard } from '../../selectors/journeyPlanner';
import GroupSaverIcon from '../../assets/groupsave.svg?react';
import Railcard from '../../assets/Railcard';
import Caret from '../../assets/Caret';
import { Price } from '../../types/journeyResults';
import { selectCurrentState } from '../../selectors/app';
import { AppGlobalStates } from '../../types/app';
import {
  selectAnyTicketHasGroupSave,
  selectAnyTicketHasRailcards,
  selectIsReturnLegSolutionAvailable,
  selectLegSolutionFromIndex,
  selectPickedOutwardPrice,
} from '../../selectors/journeyResults';
import {
  JourneyResultsDispatch,
  setPickedOutwardBookingLeg,
  setPickedOutwardPrice,
  setPickedReturnBookingLeg,
  setPickedReturnPrice,
} from '../../actions/journeyResults';
import {
  AppDispatch,
  appOpenPurchaseSummary,
  appOpenReturnJourneyResults,
} from '../../actions/app';

interface JourneyButtonProps {
  fareInfoComponent: React.ReactNode;
  moreFareComponent?: React.ReactNode;
  isCheapest: boolean;
  price: Price;
  legSolutionIndex: number;
  isClicked?: boolean;
}

const JourneyButton: React.FC<JourneyButtonProps> = ({
  isCheapest,
  fareInfoComponent,
  moreFareComponent,
  price,
  legSolutionIndex,
  isClicked,
}): React.ReactNode => {
  const dispatch = useDispatch<JourneyResultsDispatch>();
  const appDispatch = useDispatch<AppDispatch>();
  const pickedOutwardPrice: Price = useSelector(selectPickedOutwardPrice);
  // const isRailcardApplied = price?.totalAmount !== price?.totalOriginalAmount;

  const isRailcardApplied = useSelector(selectAnyTicketHasRailcards(price?.tickets));
  const isGroupSaveApplied = useSelector(selectAnyTicketHasGroupSave(price?.tickets));

  const selectedRailcards = useSelector(selectRailcard);
  const currentState = useSelector(selectCurrentState);

  const legSolution = useSelector(selectLegSolutionFromIndex(legSolutionIndex));
  let displayFare = `£${price?.totalAmount}`;

  if (pickedOutwardPrice !== null) {
    if (currentState === AppGlobalStates.ReturnJourneysResult) {
      if (pickedOutwardPrice?.isReturn) {
        price.fareId = null;
        price.toc = null;
        price.totalAmount = '0';
        price.totalOriginalAmount = '0';
        price.fareType = pickedOutwardPrice?.fareType;
        isCheapest = false;
        displayFare = `+£ 0`;
      } else {
        displayFare = `+${displayFare}`;
      }
    }
  }
  const isReturnAvailable = useSelector(selectIsReturnLegSolutionAvailable);

  const handleButtonClick = useCallback((): void => {
    if (currentState === AppGlobalStates.OutboundJourneysResult) {
      dispatch(setPickedOutwardPrice(price));
      if (legSolution !== null) {
        dispatch(setPickedOutwardBookingLeg(legSolution));
      }

      if (isReturnAvailable) {
        appDispatch(appOpenReturnJourneyResults());
        return;
      }
    } else {
      dispatch(setPickedReturnPrice(price));
      if (legSolution !== null) {
        dispatch(setPickedReturnBookingLeg(legSolution));
      }
    }
    appDispatch(appOpenPurchaseSummary());
  }, [appDispatch, currentState, dispatch, isReturnAvailable, legSolution, price]);

  useEffect(() => {
    if (isClicked) {
      handleButtonClick();
    }
  }, [handleButtonClick, isClicked]);

  return (
    <div className={styles.fareContainer}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          gap: '23px',
        }}
      >
        <div className={styles.fareInformation}>
          {fareInfoComponent}
          {isRailcardApplied && (
            <div className={styles.iconContainer}>
              {selectedRailcards.length === 1 ? selectedRailcards[0].name : 'Multiple Railcards'}
              <Railcard fill='#F07F15' className={styles.icon} />
            </div>
          )}
          {isGroupSaveApplied && (
            <div className={styles.iconContainer}>
              Group Save <GroupSaverIcon className={styles.icon} />
            </div>
          )}
        </div>
        {moreFareComponent}
      </div>
      <div className={styles.priceSection} onClick={handleButtonClick}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            alignItems: 'flex-end',
          }}
        >
          <div style={{ width: '138px' }}>
            {isCheapest && (
              <div className={styles.cheapestLabel}>
                <span>CHEAPEST</span>
              </div>
            )}
            <div
              className={classNames(styles.price, {
                [styles.isCheapest]: isCheapest,
              })}
            >
              <span>{displayFare}</span>
              <Caret fill='white' className={styles.caret} />
            </div>
          </div>
          <span style={{ paddingRight: '10px' }}>
            {' '}
            {price?.totalAmount !== price?.totalOriginalAmount && (
              <div className={styles.originalPrice}>{`£${price.totalOriginalAmount}`}</div>
            )}
          </span>
        </div>

        {currentState === AppGlobalStates.ReturnJourneysResult && (
          <span style={{ textAlign: 'right', paddingRight: '10px' }}>
            {`Total : £${(+price?.totalAmount + +pickedOutwardPrice?.totalAmount).toFixed(2)}`}
          </span>
        )}
      </div>
    </div>
  );
};

export default JourneyButton;
