import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import styles from './App.module.scss';

// Assets
import TopNotchSVG from '@/assets/TopNotch';
import NotchSVG from '@/assets/nav-notch.svg?react';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import Home from '@/assets/Home';
import Back from '@/assets/Back';

// Components
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Clock from '../Clock/Clock';
// Actions
import { AppDispatch, appInitialize, appOpenHome, appSetCurrentState } from '@/actions/app';
import { setResetJourneyResults } from '@/actions/journeyResults';

// Selectors
import { selectJourneyType } from '@/selectors/journeyPlanner';
import { selectCurrentState, selectIsHome } from '@/selectors/app';

// Types
import { JourneyType } from '@/types/journeyPlanner';
import { AppGlobalStates } from '@/types/app';

// Others
import { history } from '@/store';
import { Location } from 'history';
// import Screenshot from '../Screenshot/Screenshot';
import AccessibilityOptions from '../Accessibility/Options/Options';
import { initializeSentry } from '@/sentry';

const App: React.FC<{ children: React.ReactNode }> = ({ children }): React.ReactNode => {
  const dispatch = useDispatch<AppDispatch>();
  const isHome: boolean = useSelector(selectIsHome);
  const journeyType = useSelector(selectJourneyType);
  const currentState = useSelector(selectCurrentState);

  const appRef = useRef(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userName = queryParams.get('computer-name');
    initializeSentry(userName);
  }, []);

  useEffect(() => {
    dispatch(appInitialize());
  }, [dispatch]);

  useEffect(() => {
    const clearListener = history.listen((location: Location<unknown>, action: string) => {
      if (action === 'POP') {
        if (location?.pathname === '/journey-planner') {
          dispatch(setResetJourneyResults());
        }
        if (location?.pathname === '/journey-results') {
          if (
            journeyType === JourneyType.RETURN &&
            currentState !== AppGlobalStates.ReturnJourneysResult
          ) {
            dispatch(appSetCurrentState(AppGlobalStates.ReturnJourneysResult));
          } else {
            dispatch(appSetCurrentState(AppGlobalStates.OutboundJourneysResult));
          }
        }
      }
    });

    return () => {
      clearListener();
    };
  });

  return (
    <>
      <div
        style={{
          height: '100vh',
          display: 'grid',
        }}
        ref={appRef}
      >
        <div className={styles.clockContainer}>
          <TopNotchSVG />
          <div className={styles.stackTop}>
            <Clock />
          </div>
        </div>

        <div className={styles.notchWrapper}>
          <TopNotchSVG style={{ transform: 'rotate(180deg)' }} className={styles.boxInvert} />
          <AccessibilityOptions />
        </div>

        <div className={styles.frame}>
          <Header />
          <div className={styles.innerFrameWrapper}>
            {!isHome ? (
              <div className={styles.navigationContainer}>
                <NotchSVG className={styles.notch} />
                <div className={styles.navigationButtons}>
                  <Home onClick={() => dispatch(appOpenHome())} />
                  <Back onClick={() => history.goBack()} />
                </div>
              </div>
            ) : null}
            <div className={styles.innerFrame}>{children}</div>
            <Footer />
          </div>
        </div>
      </div>
      {/* <Screenshot screenshotRef={appRef}></Screenshot> */}
    </>
  );
};

export default App;
