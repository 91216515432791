import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import styles from './JourneyPlanner.module.scss';
import 'react-tabs/style/react-tabs.css';

import EditBox from '../../EditBox/EditBox';
import React, { useCallback, useState } from 'react';
import {
  setDepartureStation,
  setArrivalStation,
  setJourneyType,
  setEnableTravelViaAvoid,
  setTravelViaAvoidStation,
  JourneyPlannerDispatch,
  setIsTravelViaOrAvoid,
  setResetJourneyPlanner,
} from '../../../actions/journeyplanner';
import DatePicker from '../DatePicker/Datepicker';
import classNames from 'classnames';
import { AppDispatch, appOpenOutboundJourneyResults } from '../../../actions/app';
import AddViaAvoid from '../../../assets/AddViaAvoid';
import Single from '../../../assets/Single';
import Return from '../../../assets/Return';
import OpenReturn from '../../../assets/OpenReturn';
import { JourneyType, Station } from '../../../types/journeyPlanner';
import { AppGlobalStates, ModalState } from '../../../types/app';
import PassengerRailcardPicker from '../PassengerRailcardPicker/PassengerRailcardPicker';
import SearchInput from '../../SearchInput/SearchInput';
import StationPickerModal from '../StationPickerModal/StationPickerModal';

import { JourneyResultsDispatch, getJourneyResults } from '../../../actions/journeyResults';
import {
  selectArrivalStation,
  selectDepartureStation,
  selectEnableTravelViaAvoid,
  selectJourneyType,
  selectTravelViaAvoidStation,
} from '../../../selectors/journeyPlanner';
import { Flex } from '../../StyledTags/Container';
import { ButtonAccept, ButtonDeny } from '../../StyledTags/Button';
import { TextBold } from '../../StyledTags/Text';

const emptyStation: Station = {
  name: '',
  crs: '',
  nlc: '',
};

function JourneyPlanner(): React.ReactNode {
  const dispatch = useDispatch<JourneyPlannerDispatch>();
  const dispatchApp = useDispatch<AppDispatch>();
  const dispatchJourneyResult = useDispatch<JourneyResultsDispatch>();
  const { t } = useTranslation();

  const [stationPickerMode, setStationPickerMode] = useState<string>(''); // origin, destination, travel via
  const journeyType: JourneyType = useSelector(selectJourneyType);
  const viaAvoidEnable: boolean = useSelector(selectEnableTravelViaAvoid);
  const originStation: Station = useSelector(selectDepartureStation);
  const destinationStation: Station = useSelector(selectArrivalStation);
  const viaAvoidStation: Station = useSelector(selectTravelViaAvoidStation);
  const [isStationPickerOpen, setIsStationPickerOpen] = useState<ModalState>(false);

  const handleFindTimeTickets = useCallback(() => {
    if (originStation?.name?.trim().length > 0 && destinationStation?.name?.trim().length > 0) {
      dispatchApp(appOpenOutboundJourneyResults());
      dispatchJourneyResult(getJourneyResults());
    }
  }, [destinationStation?.name, dispatchApp, dispatchJourneyResult, originStation?.name]);

  const handleClearAllData = useCallback(() => {
    dispatch(setResetJourneyPlanner());
  }, [dispatch]);
  // Origin & Destination stations

  const handleStationPickerModalClose = () => {
    setIsStationPickerOpen(false);
  };

  const handleOpenStationPickerModal = useCallback((mode: string) => {
    setStationPickerMode(mode);
    setIsStationPickerOpen(true);
  }, []);

  const handleStationSelected = useCallback(
    (station: Station) => {
      switch (stationPickerMode) {
        case 'departure':
          dispatch(setDepartureStation(station));
          break;
        case 'arrival':
          dispatch(setArrivalStation(station));
          break;
        case 'viaoravoid':
          dispatch(setTravelViaAvoidStation(station));
          break;
      }
    },
    [dispatch, stationPickerMode]
  );

  const handleViaAvoidChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = event.target.value;
      dispatch(setIsTravelViaOrAvoid(selectedValue === '1'));
    },
    [dispatch]
  );

  return (
    <div className={styles.screenWrapper}>
      {/* Display Station Start and end Search Box */}
      <div className={styles.plannerSection}>
        <div className={styles.stationPickers}>
          <div className={styles.stationPicker}>
            <div className={styles.stationPickerType}>Dep:</div>
            <SearchInput
              placeHolder={t('placeholders.origin')}
              variant='small'
              value={originStation?.name}
              onClick={() => handleOpenStationPickerModal('departure')}
              onClearClicked={() => handleStationSelected(emptyStation)}
            />
          </div>

          <div className={styles.stationPicker}>
            <div className={styles.stationPickerType}>Arr:</div>
            <SearchInput
              placeHolder={t('placeholders.destination')}
              variant='small'
              value={destinationStation?.name}
              onClick={() => handleOpenStationPickerModal('arrival')}
              onClearClicked={() => handleStationSelected(emptyStation)}
            />
          </div>

          <div
            onClick={() => dispatch(setEnableTravelViaAvoid(!viaAvoidEnable))}
            className={styles.travelViaOption}
          >
            <p>Travel via or avoid?</p>
            <AddViaAvoid className={styles.addViaAvoidIcon} />
          </div>
          <div
            className={classNames(styles.stationPicker, {
              [styles.viaOrAvoidHidden]: !viaAvoidEnable,
            })}
          >
            <select onChange={handleViaAvoidChange} className={styles.dropDownWrapper}>
              <option value='0'>VIA</option>
              <option value='1'>AVOID</option>
            </select>
            <SearchInput
              placeHolder='Add station...'
              variant='small'
              value={viaAvoidStation?.name}
              onClick={() => handleOpenStationPickerModal('viaoravoid')}
              onClearClicked={() => dispatch(setTravelViaAvoidStation(emptyStation))}
            />
          </div>
          <StationPickerModal
            onStationSelect={handleStationSelected}
            openState={isStationPickerOpen}
            onModalClose={handleStationPickerModalClose}
          />
        </div>
        {/* Display Single / Return / Open Return Tabs  */}

        <Tabs
          className={styles.tabsWrapper}
          selectedIndex={journeyType as number}
          onSelect={(journeyTyp: any) => dispatch(setJourneyType(journeyTyp))}
        >
          <TabList className={styles.tabList}>
            <Tab className={styles.tab} selectedClassName={styles.activeTab}>
              <Single />
              <span>Single</span>
            </Tab>
            <Tab className={styles.tab} selectedClassName={styles.activeTab}>
              <Return />
              <span>Return</span>
            </Tab>
            <Tab className={styles.tab} selectedClassName={styles.activeTab}>
              <OpenReturn />
              <span>Open Return</span>
            </Tab>
          </TabList>

          <TabPanel className={styles.tabPanel}>
            {/* Outward Calendar Modal */}
            <DatePicker state={AppGlobalStates.OutboundDateTimePicker} label='Outbound:' />
          </TabPanel>
          <TabPanel className={styles.tabPanel}>
            <DatePicker state={AppGlobalStates.OutboundDateTimePicker} label='Outbound:' />

            {/* Return Calendar Modal */}
            <DatePicker state={AppGlobalStates.ReturnDateTimePicker} label='Return:' />
          </TabPanel>
          <TabPanel className={styles.tabPanel}>
            <DatePicker state={AppGlobalStates.OutboundDateTimePicker} label='Outbound:' />
            <EditBox label='Return within 1 month' iconName='date' textOnly />
          </TabPanel>
        </Tabs>

        <hr className={styles.hr} />

        {/*  Passenger & Railcard UI */}
        <div className={styles.tabPanel}>
          <PassengerRailcardPicker />
        </div>
      </div>
      {/* Bottom Button Section */}
      <Flex gap='30px' justifyContent='center'>
        <ButtonDeny onClick={handleClearAllData}>
          <TextBold margin='5px'>{t('button.clear')}</TextBold>
        </ButtonDeny>
        <ButtonAccept onClick={handleFindTimeTickets}>
          <TextBold margin='5px'>{t('button.find')}</TextBold>
        </ButtonAccept>
      </Flex>
    </div>
  );
}

export default JourneyPlanner;
