import { useCallback, useEffect, useMemo, useState } from 'react';

// Styles
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import Popup from 'reactjs-popup';
import { DashedProgress } from 'react-dashed-progress';
import styles from './Footer.module.scss';
/// <reference types="vite-plugin-svgr/client" />
import ApplePayLogo from '../../assets/apple-pay_available.svg?react';
import { selectCurrentState } from '../../selectors/app';
import { AppGlobalStates } from '../../types/app';
import { AppDispatch, appOpenHome } from '../../actions/app';
import { TextBold } from '../StyledTags/Text';
import { Flex, Grid, ModalWrap } from '../StyledTags/Container';
import { ButtonAccept, ButtonDeny } from '../StyledTags/Button';

import { useTranslation } from "react-i18next";

export default function Footer() {
  const dispatch = useDispatch<AppDispatch>();
  const currentState = useSelector(selectCurrentState);
  const [remaining, setRemaining] = useState<number>(0);
  const [expireModalState, setExpireModalState] = useState(false);
  const { t } = useTranslation();


  const handleOnIdle = useCallback(() => {
    console.log('[App] Session reset after journey summary');
    dispatch(appOpenHome());
    setExpireModalState(false);
  }, [dispatch]);

  const { getRemainingTime } = useIdleTimer({
    timeout: 60000,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    if (currentState === AppGlobalStates.PurchaseSummary) {
      if (remaining === 15) {
        setExpireModalState(true);
      }
    }
  }, [currentState, remaining]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout;
    if (currentState === AppGlobalStates.PurchaseSummary) {
      interval = setInterval(() => {
        const time = Math.ceil(getRemainingTime() / 1000);
        setRemaining(time);
      }, 500);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [currentState, getRemainingTime]);

  const CircularProgress = useMemo(
    () => (
      <>
        <TextBold>This session will expire in</TextBold>
        <div style={{ width: '175px', height: '175px' }}>
          <div
            style={{
              position: 'absolute',
              transform: 'scaleY(-1)',
            }}
          >
            <DashedProgress
              size={180}
              countBars={60}
              value={remaining}
              barWidth={1}
              text='testing'
              trailColor='#f2f2f2'
              strokeColor='#0b78c1'
              showTooltip={false}
              trailThickness={5}
            />
          </div>
          <div className={styles.timeoutCounter}>
            <p className={styles.timeRemaining}>{remaining}</p>
            <p className={styles.seconds}>second{remaining !== 1 ? 's' : ''}</p>
          </div>
        </div>
      </>
    ),
    [remaining]
  );

  const SessionExpireModal = useMemo(
    () => (
      <Popup open={expireModalState} closeOnDocumentClick={false}>
        <ModalWrap>
          <Grid gap='40px' justifyItems='center'>
            {CircularProgress}
            <Flex>
              <ButtonAccept onClick={() => setExpireModalState(false)}>
                <TextBold color='white'>I need more time</TextBold>
              </ButtonAccept>
              <ButtonDeny onClick={handleOnIdle}>
                <TextBold>Next customer</TextBold>
              </ButtonDeny>
            </Flex>
          </Grid>
        </ModalWrap>
      </Popup>
    ),
    [CircularProgress, expireModalState, handleOnIdle]
  );

  return (
    <div style={{ position: 'relative', display: 'grid' }}>
      {currentState !== AppGlobalStates.PurchaseSummary ? (
        <div className={styles.footerMenu}>
          <div className={styles.paymentInformation}>
            <div>
              <ApplePayLogo style={{ width: '135px', height: '72px' }} />
            </div>
            <div>
              <img style={{ width: '135px', height: '72px' }} alt='gPayLogo' src='google-pay.png' />
            </div>
            <div className={styles.text}>
              {t("footer.description")}
              This machine offers eTickets for purchase via Apple Pay and Google
              Pay only. Cash, debit or credit card payments are not accepted.
            </div>
          </div>
        </div>
      ) : (
        <>
          {SessionExpireModal}
          <Grid gap='20px' margin='35px' align='center' justifyItems='center'>
            {CircularProgress}
            <Flex justifyContent='center'>
              <ButtonDeny onClick={handleOnIdle}>
                <TextBold>Next customer</TextBold>
              </ButtonDeny>
            </Flex>
          </Grid>
        </>
      )}
    </div>
  );
}
