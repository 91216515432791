import React from 'react';

import classNames from 'classnames';
import styles from './Scaling.module.scss';

type ScalingProps = Record<string, never>;

// const ScalingOptions = {
//   '2x': 2,
//   '3x': 3,
//   Default: 1,
// };

const Scaling: React.FC<ScalingProps> = ({}): React.ReactNode => {
  const handleScaling = (event: React.MouseEvent<HTMLDivElement>, scale: number) => {
    event.stopPropagation();
    event.preventDefault();

    const metaTag = document.querySelector('html');
    console.log(metaTag);
    if (metaTag) {
      // transform: scale(2, 0.5);
      // metaTag.setAttribute("style", `zoom:${scale}`);
      metaTag.setAttribute('style', `transform:scale(${scale});transform-origin: top left;`);
      return undefined;
    }
  };

  return (
    <div className={styles.ScalingFrame}>
      <h4>Screen Contrast</h4>
      <ul>
        <li onClick={(event: any) => handleScaling(event, 2)}>
          <div className={classNames(styles.container)}>2x</div>
        </li>
        <li onClick={(event: any) => handleScaling(event, 3)}>
          <div className={classNames(styles.container)}>3x</div>
        </li>
        <li onClick={(event: any) => handleScaling(event, 1)}>
          <div className={classNames(styles.container)}>Default</div>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(Scaling);
