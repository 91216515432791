import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorBoundary } from 'react-error-boundary';
import App from '#/App/App';
import configureStore, { history } from './store';
import './styles/index.scss';
import './i18n';
// Screens
import JourneyResults from '&/JourneyResults/JourneyResults';
import JourneyPlanner from '&/JourneyPlanner/JourneyPlanner';
import PurchaseSummary from '&/PurchaseSummary/PurchaseSummary';
import Home from '&/Home/Home';
import NoService from '&/NoService/NoService';

// handle global exceptions
const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <ErrorBoundary fallback={<p>An error has occurred</p>}>
      <App>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path='/' component={() => <Home />} />
            <Route exact path='/journey-planner' component={() => <JourneyPlanner />} />
            <Route exact path='/journey-results' component={() => <JourneyResults />} />
            <Route exact path='/purchase-summary' component={() => <PurchaseSummary />} />
            <Route exact path='/no-service' component={() => <NoService />} />
            <Redirect to='/' /> {/* Redirect unknown routes to the root path */}
          </Switch>
        </ConnectedRouter>
      </App>
    </ErrorBoundary>
  </Provider>
);
