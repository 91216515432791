import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { sumBy } from 'lodash-es';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { nanoid } from 'nanoid';
import styles from './RailcardSelector.module.scss';
import RailcardIcon from '../../assets/Railcard';
import IncreaseEnable from '../../assets/IncreaseEnable.svg?react';
import RemoveRailcard from '../../assets/remove.svg?react';
import IncreaseDisable from '../../assets/IncreaseDisable.svg?react';
import ReduceDisable from '../../assets/reduceDisable.svg?react';
import ReduceEnable from '../../assets/reduceEnable.svg?react';

import { selectRailcardList } from '../../selectors/railcards';
import { Railcard, Railcards } from '../../types/journeyPlanner';

import { selectRailcard } from '../../selectors/journeyPlanner';

interface RailcardSelectorProps {}

export interface RailCardPickerRef {
  getSelectedRailCards: () => Railcards;
  updateTotalPossibleRailcards: (total: number) => void;
  resetPrevState: () => void;
  getTotalCount: () => number;
}

function RailcardSelector(
  {}: RailcardSelectorProps, // Remove the 'ref' parameter from here
  ref: React.Ref<RailCardPickerRef>
) {
  const railcardState: Railcards = useSelector(selectRailcard);

  const railCards: Railcards = useSelector(selectRailcardList);

  const [totalPassenger, setTotalPassenger] = useState(10);
  const [railcardValueCounts, setRailcardValueCounts] = useState<Railcards>([]);

  const totalSelectRailcardCount = sumBy(railcardValueCounts, 'count');

  useEffect(() => {
    setRailcardValueCounts(railcardState);
  }, [railcardState]);

  useImperativeHandle(ref, () => ({
    getSelectedRailCards: (): Railcards => railcardValueCounts,
    updateTotalPossibleRailcards: (total): void => {
      setTotalPassenger(total);
    },
    resetPrevState: (): void => {
      setRailcardValueCounts(railcardState);
    },
    getTotalCount: (): number => totalSelectRailcardCount,
  }));

  const handleAddRailcard = useCallback(() => {
    if (totalSelectRailcardCount < totalPassenger) {
      const newOption: Railcard = {
        code: '',
        name: 'Select your railcard',
        count: 1,
        id: nanoid(),
      };
      const newRailcardValueCounts = [...railcardValueCounts, newOption];
      setRailcardValueCounts(newRailcardValueCounts);
    }
  }, [railcardValueCounts, totalPassenger, totalSelectRailcardCount]);

  const handleRemoveRailcard = useCallback(
    (idToBeRemoved: string) => {
      const newRailcardValueCounts = railcardValueCounts.filter(
        (item) => item.id !== idToBeRemoved
      );
      setRailcardValueCounts(newRailcardValueCounts);
    },
    [railcardValueCounts]
  );

  const handleSelectRailcard = useCallback(
    (state: Railcard, railCard: Railcard) => {
      const newRailcardValueCounts = railcardValueCounts.map((item) => {
        if (item.id === state.id) {
          item.name = railCard.name;
          item.code = railCard.code;
        }
        return item;
      });

      setRailcardValueCounts(newRailcardValueCounts);
    },
    [railcardValueCounts]
  );

  const handleIncreaseDecreaseRailcardCount = useCallback(
    (idToBeModified: string, increase: boolean = true) => {
      const newRailcardValueCounts = railcardValueCounts.map((item) => {
        if (item.id === idToBeModified) {
          item.count += increase ? 1 : -1;
        }
        return item;
      });
      const updatedRailcardValueCounts = newRailcardValueCounts.filter((item) => item.count !== 0);

      setRailcardValueCounts(updatedRailcardValueCounts);
    },
    [railcardValueCounts]
  );

  return (
    <div className={styles.railcardWrapper}>
      <div className={classNames(styles.addRailcardSection, styles.title)}>
        <RailcardIcon className={styles.svg} />
        <span>Railcards</span>
      </div>
      <div className={styles.addRailcardSection} onClick={handleAddRailcard}>
        {totalSelectRailcardCount < totalPassenger ? (
          <>
            <IncreaseEnable className={styles.svg} />
            <div className={styles.addText}>Add Railcard </div>
          </>
        ) : (
          <>
            <IncreaseDisable className={styles.svg} />
            <div className={styles.disableAddText}>Add Railcard </div>
          </>
        )}
        {totalPassenger < totalSelectRailcardCount && (
          <div className={styles.errorHighlight}>
            Can’t proceed There are more railcards than eligible passengers. Add more passengers or
            remove railcards to continue.
          </div>
        )}
      </div>
      <div className={styles.multipleRailcardContainer}>
        {railcardValueCounts.map((railcardValueCount) => (
          <div className={styles.addRailcardSectionWrapper} key={railcardValueCount.id}>
            <div className={styles.addRailcardSection}>
              <RemoveRailcard
                className={styles.svg}
                onClick={() => handleRemoveRailcard(railcardValueCount.id)}
              />
              {/* Drop down code */}

              <DropdownMenu
                trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
                  <div
                    className={styles.editBox}
                    ref={triggerRef as React.Ref<HTMLDivElement>}
                    {...providedProps}
                  >
                    {railcardValueCount.name}
                  </div>
                )}
              >
                <DropdownItemGroup>
                  <div className={styles.dropDownGroup}>
                    {railCards.map((railCard) => (
                      <DropdownItem
                        key={railCard?.code}
                        onClick={() => handleSelectRailcard(railcardValueCount, railCard)}
                        elemBefore={
                          railcardValueCount.code === railCard.code && <div>&#x2713;</div>
                        }
                      >
                        <div
                          className={classNames(styles.menuItems, {
                            [styles.bold]: railcardValueCount.code === railCard.code,
                          })}
                        >
                          {railCard.name}
                        </div>
                      </DropdownItem>
                    ))}
                  </div>
                </DropdownItemGroup>
              </DropdownMenu>
            </div>
            <div className={styles.counter}>
              {railcardValueCount.count === 0 ? (
                <ReduceDisable />
              ) : (
                <ReduceEnable
                  onClick={() => handleIncreaseDecreaseRailcardCount(railcardValueCount.id, false)}
                />
              )}

              <div>{railcardValueCount.count}</div>
              {totalSelectRailcardCount >= totalPassenger ? (
                <IncreaseDisable />
              ) : (
                <IncreaseEnable
                  onClick={() => handleIncreaseDecreaseRailcardCount(railcardValueCount.id)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default forwardRef(RailcardSelector);
