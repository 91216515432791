import { StringNullify } from './app';
import { Railcard, Station, ToknUTC } from './journeyPlanner';

export type Results = {
  outwardLegSolutions: LegSolution[];
  outwardCheapest: string;
  returnLegSolutions: LegSolution[];
  returnCheapest: string;
};

export type Validity = {
  from: string;
  until: string;
  period: {
    days: number;
  };
  break: boolean;
};

export type JourneyResultsState = {
  responseStatus: boolean;
  results: Results;
  pickedOutwardPrice: PriceNullish;
  pickedReturnPrice: PriceNullish;
  pickedOutwardBookingLegs: LegSolutionNullish;
  pickedReturnBookingLegs: LegSolutionNullish;
};

export type DateTime = ToknUTC;

export type TravelSegment = {
  type: string;
  origin: Station;
  destination: Station;
  departureDateTime: DateTime;
  arrivalDateTime: DateTime;
  operator: {
    code: string;
    name: string;
  };
};
export type StationNLC = string;

export type Price = {
  origin: string;
  destination: string;
  totalAmount: string;
  totalOriginalAmount: string;
  currency: string;
  fareType: string;
  fareId: StringNullify;
  toc: StringNullify;
  firstClass: boolean;
  alternateDestinations: StationNLC[];
  alternateOrigins: StationNLC[];
  isReturn: boolean;
  routeName: string;
  routeDescription: string;
  outwardValidity: Validity;
  returnValidity?: Validity;
  eticketCollection: boolean;
  tickets: Ticket[];
};

export type PriceNullish = Price | null;
export type LegSolutionNullish = LegSolution | null;

export type JourneyTime = {
  scheduledTime: DateTime;
  adjustedTime: DateTime;
  confidence: string;
  delayed: boolean;
};
export type LegSolution = {
  id: string;
  origin: string;
  destination: string;
  departureTime: JourneyTime;
  arrivalTime: JourneyTime;
  direct: boolean;
  overtaken: boolean;
  isCancelled: boolean;
  duration: string;
  changes: number;
  travelSegments: TravelSegment[];
  cheapest: {
    outwardSingle?: Price[] | undefined;
    return?: Price[] | undefined;
    inboundSingle: Price[];
    [key: string]: Price[] | undefined;
  };
  prices: Price[];
  isReturn: boolean;
  bookingLegs: any;
};

export type Ticket = {
  price: string;
  originalPrice: string;
  adults: number;
  children: number;
  firstClass?: boolean;
  railcardDiscount?: string;
  railcard: Railcard | null;
};

export type FareInfo = {
  adult: number;
  children: number;
  adultPrice: string;
  childrenPrice: string;
  railcard: string;
};

export type ActionTypes = {
  type: string;
  data: Price | JourneyTime | LegSolution | boolean | Results | string;
};

export const LegSolutionMustNeedAttrNames: string[] = [
  'id',
  'origin',
  'destination',
  'departureTime',
  'arrivalTime',
  'direct',
  'overtaken',
  'isCancelled',
  'duration',
  'changes',
  'travelSegments',
  'cheapest',
  'prices',
  'isReturn',
  'bookingLegs',
];

export enum FaresType {
  Return = 'return',
  OutwardSingle = 'outwardSingle',
  InboundSingle = 'inboundSingle',
}

export type SegmentInfo = {
  station: string;
  // stationObj: ;

  time?: {
    scheduledTime: ToknUTC;
    adjustedTime: ToknUTC;
    delayed: boolean;
  };
  pattern: 'PickUpOnly' | 'Passing' | 'Normal' | 'SetDownOnly';
  platform?: string;
};
