// App Constants
export const INIT = 'INIT';

export const SET_CURRENT_STATE = 'SET_CURRENT_STATE';
// Stations Constants
export const SET_STATIONS = 'SET_STATIONS';
export const SET_POPULAR_STATIONS = 'SET_POPULAR_STATIONS';
// Railcards Constants
export const SET_RAILCARDS = 'SET_RAILCARDS';

// TOCs Constants
export const RESET_HOME = 'RESET_HOME';

export const SET_SELECTED_RAILCARD = 'SET_SELECTED_RAILCARD';

// journey Results constants
export const SET_IS_LOADING_JOURNEY_RESULTS = 'SET_IS_LOADING_JOURNEY_RESULTS';

export const SET_JOURNEY_RESULTS = 'SET_JOURNEY_RESULTS';

export const SET_PICKED_OUTBOUND_JOURNEY = 'SET_PICKED_OUTBOUND_JOURNEY';

export const SET_PICKED_OUTBOUND_BOOKING_LEG = 'SET_PICKED_OUTBOUND_BOOKING_LEG';

export const SET_PICKED_RETURN_BOOKING_LEG = 'SET_PICKED_RETURN_BOOKING_LEG';

export const SET_PICKED_RETURN_JOURNEY = 'SET_PICKED_RETURN_JOURNEY';
// journey Planner constants

export const RESET_JOURNEY_PLANNER = 'RESET_JOURNEY_PLANNER';

export const RESET_JOURNEY_TIME = 'RESET_JOURNEY_TIME';

// tok-n Journey Planner Constants
export const SINGLE = 0;
export const RETURN = 1;
export const OPENRETURN = 2;

export const OUTWARD_JOURNEY = 0;
export const RETURN_JOURNEY = 1;

export const DEPARTURE = 0;
export const ARRIVAL = 1;

export const SET_DEPARTURE_STATION = 'SET_DEPARTURE_STATION';
export const SET_ARRIVAL_STATION = 'SET_ARRIVAL_STATION';

export const SET_TRAVEL_VIA_VOID = 'SET_TRAVEL_VIA_VOID';
export const SET_PASSENGER_COUNT = 'SET_PASSENGER_COUNT';

export const SET_IS_TRAVEL_VIA_OR_AVOID = 'SET_IS_TRAVEL_VIA_OR_AVOID';

export const SET_JOURNEY_TYPE = 'SET_JOURNEY_TYPE';
export const SET_TRAVEL_VIA_AVOID_STATION = 'SET_TRAVEL_VIA_AVOID_STATION';

// outbound
export const SET_OUTBOUND_IS_ARRIVAL = 'SET_OUTBOUND_IS_ARRIVAL';
export const SET_OUTBOUND_DATE_TIME = 'SET_OUTBOUND_DATE_TIME';
export const SET_OUTBOUND_START_UTC = 'SET_OUTBOUND_START_UTC';
export const SET_OUTBOUND_END_UTC = 'SET_OUTBOUND_END_UTC';

// return
export const SET_RETURN_IS_ARRIVAL = 'SET_RETURN_IS_ARRIVAL';
export const SET_RETURN_DATE_TIME = 'SET_RETURN_DATE_TIME';
export const SET_RETURN_START_UTC = 'SET_RETURN_START_UTC';
export const SET_RETURN_END_UTC = 'SET_RETURN_END_UTC';

export const RESET_JOURNEY_RESULTS = 'RESET_JOURNEY_RESULTS';
// Bookings Constants

export const SET_IS_CREATING_BOOKING = 'SET_IS_CREATING_BOOKING';
export const SET_BOOKING = 'SET_BOOKING';
export const SET_ERROR_CREATING_BOOKING = 'SET_ERROR_CREATING_BOOKING';
export const RESET_BOOKING = 'RESET_BOOKING';

// accessibility
export const SET_SCALING_PREVIEW_STATE = 'SET_SCALING_PREVIEW_STATE';
