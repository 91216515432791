import * as Sentry from '@sentry/react';
import { history } from './store';
export function initializeSentry(userName: string | null) {
  Sentry.init({
    dsn: 'https://e16c862d796a4af592297923cc6c219d@sentry.kadfire.com/10',
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration({
        maskAllInputs: false,
        blockAllMedia: false,
        maskAllText: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    //Add list of URLs to be traced
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 0,
  });
  if (userName != null) {
    Sentry.setUser({
      id: userName,
      ip_address: '{{auto}}',
    });
  }
  // Sentry.setTags({
  //set extra details about the machine
  // });
}

export function getSentryDetails() {
  const userScope = Sentry.getCurrentScope();
  const user = userScope.getUser();
  return user;
}
