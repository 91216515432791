// https://github.com/typescript-eslint/typescript-eslint/blob/main/packages/eslint-plugin/docs/rules/naming-convention.md
// For later purpose to make it strong and maintain standard tok-n eslint rules

export interface ToknDate {
  currDateISO: string;
  day: number;
  dayString: string;
  month: number;
  monthString: string;
  monthName: string;
  monthShortName: string;
  year: number;
  date: string;
  dayName: string;
  dayShortName: string;
  expression: string;
}

export interface ToknTime {
  time: string;
  hour: number;
  minute: number;
  expression: string;
}

export type ToknUTC = string;

export type DateTime = {
  date: ToknDate;
  time: ToknTime;
  utc: ToknUTC;
};

export enum JourneyType {
  SINGLE = 0,
  RETURN = 1,
  OPENRETURN = 2,
}

export enum ViaAvoid {
  VIA = 0,
  AVOId = 1,
}

export enum ArrivalDepart {
  DEPARTURE = 0,
  ARRIVAL = 1,
}
export type Station = {
  name: string;
  nlc: string;
  crs?: string;
  operator?: string;
};

export type TravelViaOrAvoid = {
  isViaAvoid: ViaAvoid;
  station: Station;
  enabled: boolean;
};

export type Passengers = {
  adult: number;
  child: number;
};

export type Railcard = {
  code: string;
  name: string;
  minAdults?: number;
  maxAdults?: number;
  minChildren?: number;
  maxChildren?: number;
  count?: number;
  id?: string;
};

export type Railcards = Array<Railcard>;

export type DateTimeUTC = string;

export type DateTimePicker = {
  dateTime: DateTime;
  startUTC?: DateTimeUTC;
  endUTC?: DateTimeUTC;
  isArrival: ArrivalDepart;
};
export type JourneyDetails = {
  outbound: DateTimePicker;
  return: DateTimePicker;
  journeyType: JourneyType;
};

export interface JourneyPlannerState {
  departureStation: Station;
  arrivalStation: Station;
  travelViaOrAvoid: TravelViaOrAvoid;
  journeyDetails: JourneyDetails;
  passenger: Passengers;
  railcard: Railcards;
}

export type ActionTypes = {
  type: string;
  data:
    | Station
    | TravelViaOrAvoid
    | ViaAvoid
    | JourneyDetails
    | JourneyType
    | Passengers
    | Railcards
    | boolean
    | DateTime
    | ArrivalDepart
    | ToknUTC
    | null;
};

export enum JourneyDirection {
  OUTBOUND = 0,
  RETURN = 1,
}

export enum DateTimeType {
  OutboundDateTime = 1,
  OutboundEndDateTime = 2,
  ReturnDateTime = 3,
  ReturnEndDateTime = 4,
}
