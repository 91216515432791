import { StringNullify } from './app';
import { Ticket } from './journeyResults';

export type BookingBase = {
  id: number | null;
  deviceToken: StringNullify;
  channel: StringNullify;
  brand: StringNullify;
  totalPrice: StringNullify;
  expiry: StringNullify;
  creationDate: StringNullify;
  state: StringNullify;
};

export interface Booking extends BookingBase {
  // id: number;
  //  deviceToken: string;
  customerName: StringNullify;
  customerId: StringNullify;
  delivery: StringNullify;
  nlc: string;
  // brand: string;
  // channel: string;
  referrer: StringNullify;
  campaign: StringNullify;
  collectionReference: StringNullify;
  email: StringNullify;
  telephoneNumber: StringNullify;
  //totalPrice: string;
  leftToPay: string;
  amendPriceDiff: number;
  // state: string;
  //creationDate: string;
  purchaseDate: StringNullify;
  lastUpdated: StringNullify;
  refundRejectionDate: StringNullify;
  refundRejectReason: StringNullify;
  trips: string[];
  //expiry: string;
  payments: {
    cards: null;
    warrants: null;
    paypal: null;
    applePay: null;
    googlePay: null;
    amazonPay: null;
    hostedCards: null;
  };
  sundries: StringNullify;
  amendedTickets: any[]; // You might want to replace `any` with a more specific type
  refundCanBeAttemptedOnline: StringNullify;
  refundCanBeAttemptedOffline: StringNullify;
  refundsLocked: boolean;
  discount: StringNullify;
  isInterop: boolean;
  totalRewardPoints: number;
  rewardPointUid: StringNullify;
  rewardName: StringNullify;
  rewardExpiryDate: StringNullify;
  promotionalDiscount: StringNullify;
  outwardJourney: BookedJourney;
  returnJourney?: BookedJourney;
  tickets: Ticket[];
}

export type BookedLeg = {
  validFrom: string;
  validTo: string;
  validityPeriod: string;
  legs: Leg[];
};

export type BookedJourney = {
  legs: BookedLeg;
  departureTime: string;
  arrivalTime: string;
  outwardValidityFrom: string;
  outwardValidityTo: string;
  fareType: string;
  routeName: string;
  routeDescription: string;
};

interface Leg {
  id: number;
  origin: string;
  destination: string;
  departure: string;
  arrival: string;
  transferTime: StringNullify;
  mode: string;
  toc: string;
  seats: any[]; // You might want to replace `any` with a more specific type
  reservable: string;
  facilities: string;
  rsid: string;
  tuid: string;
  isTemporaryTrain: boolean;
}

export enum BookingStatus {
  Incomplete = 'Incomplete',
  Processing = 'Processing',
  Fulfilled = 'Fulfilled',
}

export type BookingState = {
  booking: BookingBase;
  isCreatingBooking: boolean;
  qrCode: StringNullify;
  errorCreatingBooking: boolean;
};

export type ActionTypes = {
  type: string;
  data: boolean | StringNullify | BookingBase;
};
