import qs from 'qs';

import {
  SET_IS_LOADING_JOURNEY_RESULTS,
  SET_JOURNEY_RESULTS,
  SET_PICKED_RETURN_JOURNEY,
  SET_PICKED_OUTBOUND_JOURNEY,
  RETURN,
  RESET_JOURNEY_RESULTS,
  OPENRETURN,
  SET_PICKED_OUTBOUND_BOOKING_LEG,
  SET_PICKED_RETURN_BOOKING_LEG,
} from './constants';

import {
  selectDepartureStation,
  selectArrivalStation,
  selectPassenger,
  selectJourneyType,
  selectEnableTravelViaAvoid,
  selectViaAvoidOption,
  selectTravelViaAvoidStation,
  selectIsRailcardApplied,
  selectRailcardCodes,
  selectOutboundIsArrival,
  selectReturnIsArrival,
  selectOutboundStartUTC,
  selectReturnStartUTC,
  selectOutboundEndUTC,
  selectReturnEndUTC,
  selectPassengerCount,
} from '@/selectors/journeyPlanner';
import {} from '@/selectors/journeyResults';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  ActionTypes,
  JourneyResultsState,
  LegSolution,
  LegSolutionMustNeedAttrNames,
  Price,
  Results,
  FaresType,
} from '@/types/journeyResults';
import { CallHistoryMethodAction } from 'connected-react-router';
import { ArrivalDepart, JourneyType, Passengers, Station } from '@/types/journeyPlanner';

import axiosHttp from './axios';
import { partition, pick } from 'lodash-es';
import { GetState, State } from '@/types/app';

export type ThunkResult<R> = ThunkAction<R, JourneyResultsState, undefined, any>;

export type JourneyResultsDispatch = ThunkDispatch<
  JourneyResultsState,
  undefined,
  ActionTypes | CallHistoryMethodAction
>;

export const setIsLoadingJourneyResults =
  (data: boolean): ThunkResult<void> =>
  (dispatch: JourneyResultsDispatch) => {
    dispatch({
      type: SET_IS_LOADING_JOURNEY_RESULTS,
      data,
    });
  };

export const setJourneyResults =
  (data: Results): ThunkResult<void> =>
  (dispatch: JourneyResultsDispatch) => {
    dispatch({
      type: SET_JOURNEY_RESULTS,
      data,
    });
  };

export const setPickedOutwardPrice =
  (data: Price): ThunkResult<void> =>
  (dispatch: JourneyResultsDispatch) => {
    dispatch({
      type: SET_PICKED_OUTBOUND_JOURNEY,
      data,
    });
  };

export const setPickedOutwardBookingLeg =
  (data: LegSolution): ThunkResult<void> =>
  (dispatch: JourneyResultsDispatch) => {
    dispatch({
      type: SET_PICKED_OUTBOUND_BOOKING_LEG,
      data,
    });
  };

export const setPickedReturnBookingLeg =
  (data: LegSolution): ThunkResult<void> =>
  (dispatch: JourneyResultsDispatch) => {
    dispatch({
      type: SET_PICKED_RETURN_BOOKING_LEG,
      data,
    });
  };

export const setPickedReturnPrice =
  (data: Price): ThunkResult<void> =>
  (dispatch: JourneyResultsDispatch) => {
    dispatch({
      type: SET_PICKED_RETURN_JOURNEY,
      data,
    });
  };

export const setResetJourneyResults = (): any => (dispatch: any) => {
  dispatch({
    type: RESET_JOURNEY_RESULTS,
    data: null,
  });
};

function mergeLegSolutions(
  legSolutionsWithGroupSave: LegSolution[],
  legSolutionsWithoutGroupSave: LegSolution[]
): (LegSolution | undefined)[] {
  return legSolutionsWithoutGroupSave.map((legSolutionNoGS) => {
    const legSolutionGS = legSolutionsWithGroupSave.find((leg) => leg.id === legSolutionNoGS.id);

    if (legSolutionGS) {
      legSolutionNoGS.prices.forEach((priceNOGS) => {
        const priceGS = legSolutionGS.prices.find((price) => price.fareId === priceNOGS.fareId);

        if (priceGS) {
          Object.assign(priceGS, priceNOGS);
        } else {
          legSolutionGS.prices.push(priceNOGS);
        }
      });
    }
    return legSolutionGS;
  });
}

const findSmallestTotalAmount = (
  legSolutions: LegSolution[],
  keys: string[]
): string | undefined => {
  let smallestTotalAmount: string | undefined;

  legSolutions.forEach((legSolution) => {
    keys.forEach((key) => {
      const prices: Price[] | undefined = legSolution.cheapest[key];
      if (prices) {
        prices.forEach((price: Price) => {
          const currentTotalAmount = price.totalAmount;
          if (!smallestTotalAmount || +currentTotalAmount < +smallestTotalAmount) {
            smallestTotalAmount = currentTotalAmount;
          }
        });
      }
    });
  });

  return smallestTotalAmount;
};

const sortLegSolutionsByAdjustedTime = (legSolutions: LegSolution[]): LegSolution[] =>
  [...legSolutions].sort((a, b) =>
    a.departureTime.adjustedTime.localeCompare(b.departureTime.adjustedTime)
  );

const validateParseJourneyResults = (legSolution: any) => (dispatch: JourneyResultsDispatch) => {
  // Write code using ZOD nom package ot compare the data with template and type
  try {
    if (legSolution !== null) {
      // Pick the necessary values
      let legSolutionsSubset: LegSolution[] = legSolution.map((leg: any) =>
        pick(leg, LegSolutionMustNeedAttrNames)
      );

      // Sort the prices based on total amount
      legSolutionsSubset.forEach((leg: LegSolution) => {
        leg.prices.sort((a, b) => +a.totalAmount - +b.totalAmount);
      });

      // Sort the LegSolution based on adjusted time
      legSolutionsSubset = sortLegSolutionsByAdjustedTime(legSolutionsSubset);

      // Split the journeys into outbound and return
      const [returnLegSolutions, outwardLegSolutions] = partition(legSolutionsSubset, 'isReturn');

      const keysForOutwardLegs = [FaresType.OutwardSingle, FaresType.Return];
      const keysForReturnLegs = [FaresType.InboundSingle];

      // find the Lowest Cheap price comparing all outbound journeys
      const outwardCheapest = findSmallestTotalAmount(
        outwardLegSolutions,
        keysForOutwardLegs
      ) as string;

      // find the Lowest Cheap price comparing all return journeys
      const returnCheapest = findSmallestTotalAmount(
        returnLegSolutions,
        keysForReturnLegs
      ) as string;

      // set the results to the redux store
      const results: Results = {
        outwardLegSolutions,
        returnLegSolutions,
        outwardCheapest,
        returnCheapest,
      };

      dispatch(setJourneyResults(results));
    }
  } catch (ex) {
    console.log('Exception Raised in validateParseJourneyResults', ex);
  } finally {
    dispatch(setIsLoadingJourneyResults(true));
  }
};

export const callJourneyPlannerAPI =
  (disableGroupSaveFeature: boolean = false): any =>
  (_dispatch: JourneyResultsDispatch, getState: GetState): Promise<LegSolution[] | null> => {
    const currentState: State = getState();

    const departureStation: Station = selectDepartureStation(currentState);
    const arrivalStation: Station = selectArrivalStation(currentState);

    const isRailcardAvailable: boolean = selectIsRailcardApplied(currentState);

    const journeyType: JourneyType = selectJourneyType(currentState);

    const outboundUTC = selectOutboundStartUTC(getState());

    const returnUTC = selectReturnStartUTC(getState());

    const outboundEndUTC = selectOutboundEndUTC(getState());

    const returnEndUTC = selectReturnEndUTC(getState());

    const railCardsCodes: string[] = selectRailcardCodes(getState());

    const outboundIsArrival: ArrivalDepart = selectOutboundIsArrival(getState());
    const returnIsArrival: ArrivalDepart = selectReturnIsArrival(getState());

    // Handling Travel Via or Avoid station nlc to the journey paramets
    const isTravelViaAvoidEnabled: boolean = selectEnableTravelViaAvoid(getState());

    const { nlc }: Station = selectTravelViaAvoidStation(getState());

    const VIA = 0;
    // const AVOID = "1"
    const option = selectViaAvoidOption(getState());

    console.log('------- Journey Planner API ----------');

    const { adult, child }: Passengers = selectPassenger(getState());

    if (departureStation?.name === '') {
      return Promise.resolve(null);
    }

    return new Promise((resolve) => {
      axiosHttp
        .get(`/journey-plan`, {
          params: {
            origin: departureStation?.nlc,
            destination: arrivalStation?.nlc,
            adults: adult,
            children: child,
            numJourneys: 5,
            disableGroupSavings: disableGroupSaveFeature,
            ...(outboundIsArrival
              ? {
                  outboundArrivalDateTime: outboundUTC,
                }
              : {
                  outboundDepartureDateTime: outboundUTC,
                }),
            rangeDateTimeEnd: outboundEndUTC,
            ...(journeyType === OPENRETURN && {
              openReturn: true,
            }),
            ...(isRailcardAvailable && {
              fareQualifier: `[${railCardsCodes}]`,
            }),
            ...(journeyType === RETURN && {
              return: true,
              rangeReturnDateTimeEnd: returnEndUTC,
              ...(returnIsArrival
                ? {
                    returningArrivalDateTime: returnUTC,
                  }
                : {
                    returningDepartureDateTime: returnUTC,
                  }),
            }),
            ...(isTravelViaAvoidEnabled && {
              ...(nlc !== '' && {
                ...(option === VIA
                  ? {
                      via: `["${nlc}"]`,
                    }
                  : {
                      avoid: `["${nlc}"]`,
                    }),
              }),
            }),
          },
          paramsSerializer: (params) => {
            console.log(JSON.stringify(params));
            return qs.stringify(params, { encode: false });
          },
        })
        .then(({ data }) => {
          const { legSolution } = data?.legs[0];
          resolve(legSolution);
        })
        .catch((ex) => {
          console.log('[ getJourneyResults Exception ] : ', ex);
          resolve(null);
        });
    });
  };

export const getJourneyResults =
  (): any =>
  (dispatch: any, getState: GetState): any => {
    const totalCount: number = selectPassengerCount(getState());
    dispatch(callJourneyPlannerAPI()).then((withGroupSaveLegSolution: LegSolution[] | null) => {
      if (totalCount > 2 && withGroupSaveLegSolution !== null) {
        dispatch(callJourneyPlannerAPI(true)).then((withoutGroupSaveLegSolution: LegSolution[]) => {
          const mergedLegSolutions: LegSolution[] = mergeLegSolutions(
            withGroupSaveLegSolution,
            withoutGroupSaveLegSolution
          ) as LegSolution[];
          dispatch(validateParseJourneyResults(mergedLegSolutions));
        });
      } else {
        dispatch(validateParseJourneyResults(withGroupSaveLegSolution));
      }
    });
  };
