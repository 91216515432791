import './TimeLineProgress.css';
import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { SegmentInfo } from '../../types/journeyResults';
import { CallingPoint } from '../CallingPoint/CallingPoint';

type TimeLineProgressProps = { segmentInfo: SegmentInfo[] };

export function TimeLineProgress({ segmentInfo }: TimeLineProgressProps): React.JSX.Element {
  const origin = segmentInfo[0];
  const destination = segmentInfo[segmentInfo.length - 1];
  const showCallingPoints = segmentInfo.length > 2;

  const [expandCallingPoints, setExpandCallingPoints] = useState(false);

  const handleOnCallingPointsClick = () => {
    setExpandCallingPoints(!expandCallingPoints);
  };

  const hideCallingPoints = showCallingPoints && (
    <u onClick={handleOnCallingPointsClick} style={{ paddingTop: '25px', color: '#0B78C1' }}>
      {expandCallingPoints ? 'Hide calling points' : 'Show calling points'}
    </u>
  );
  return (
    <div className='outer' key={nanoid()}>
      <div className='progress'>
        <div className='right'>
          <CallingPoint segmentInfo={origin} firstSegment hideCallingPoint={hideCallingPoints} />
          {showCallingPoints &&
            expandCallingPoints &&
            segmentInfo.slice(1, segmentInfo.length - 1).map((segment) => (
              <React.Fragment key={nanoid()}>
                <CallingPoint segmentInfo={segment} />
              </React.Fragment>
            ))}
          <CallingPoint segmentInfo={destination} lastSegment />
        </div>
      </div>
    </div>
  );
}
