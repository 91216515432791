import React from 'react';

function ClearActive({ className, onClick }: { className?: string; onClick?: () => void }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='80'
      height='80'
      viewBox='0 0 36 36'
      className={className}
      onClick={onClick}
    >
      <g data-name='Group 1450' transform='translate(-265 -978)'>
        <g data-name='Group 1196' transform='translate(-332 251)'>
          <circle
            cx='18'
            cy='18'
            r='18'
            fill='#0b78c1'
            data-name='Ellipse 111'
            transform='translate(597 727)'
          />
        </g>
        <path
          fill='#fff'
          d='M6.614 0v6.614H0v2.705h6.614v6.614h2.7V9.319h6.614V6.614h-6.61V0z'
          data-name='Path 1095'
          transform='rotate(45 -1047.156 833.92)'
        />
      </g>
    </svg>
  );
}

export default React.memo(ClearActive);
