import React, { HTMLAttributes, ReactNode } from 'react';

interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  color?: string;
  background?: string;
}

const btnStyles: React.CSSProperties = {
  padding: '15px 30px',
  borderRadius: '36px',
  boxShadow: '0px 5px 10px #00000033',
  border: 'none',
};

export const ButtonAccept: React.FC<ButtonProps> = ({
  children,
  color,
  background,
  ...restProps
}) => {
  const styles: React.CSSProperties = {
    ...btnStyles,
    color: color ?? '#ffffff',
    background: background ?? '#0b78c1',
  };
  return (
    <div style={styles} {...restProps}>
      {children}
    </div>
  );
};

export const ButtonDeny: React.FC<ButtonProps> = ({
  children,
  color,
  background,
  ...restProps
}) => {
  const styles: React.CSSProperties = {
    ...btnStyles,
    color: color ?? '#1e1e50',
    background: background ?? '#ffffff',
  };
  return (
    <div style={styles} {...restProps}>
      {children}
    </div>
  );
};
