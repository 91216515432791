import React from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Accessibility = React.forwardRef<SVGSVGElement, SVGRProps>((props, ref) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={58}
    height={58}
    viewBox='0 0 45 45'
    {...props}
    ref={ref}
  >
    <path d='M18,0A18,18,0,1,1,0,18,18,18,0,0,1,18,0Z' fill='#0089ef' />
    <path
      d='M13.714,12.877l-1.346-.5-1.015,2.7,1.352.5,3.845,1.442.024.012v3.214l-1.85,5.545-.457,1.37L17,28.071l.457-1.37,1.6-4.782h.805L21.453,26.7l.457,1.37,2.734-.913-.457-1.37-1.844-5.545V17.029l.024-.012,3.845-1.442,1.352-.5-1.015-2.7-1.352.5-3.6,1.352H17.319l-3.6-1.352Zm5.744-.571a2.4,2.4,0,1,0-2.4-2.4A2.4,2.4,0,0,0,19.458,12.306Z'
      transform='translate(-1.458 0.214)'
      fill='#fff'
    />
  </svg>
));
Accessibility.displayName = 'Accessibility';
export default Accessibility;
