import { sortBy } from 'lodash-es';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { CallHistoryMethodAction } from 'connected-react-router';
import { Railcards } from '@/types/journeyPlanner';
import axiosHttp from './axios';

import { SET_RAILCARDS } from './constants';
import { ActionTypes, RailcardState } from '@/types/railcard';

// Selectors
export type ThunkResult<R> = ThunkAction<R, RailcardState, undefined, ActionTypes>;

export type RailcardDispatch = ThunkDispatch<
  RailcardState,
  undefined,
  ActionTypes | CallHistoryMethodAction
>;
// Data
// import localRailcards from '../assets/offline-data/railcards.json'

export const setRailcards = (railcards: Railcards) => (dispatch: RailcardDispatch) =>
  dispatch({
    type: SET_RAILCARDS,
    data: railcards,
  });

export const getRailcards = () => (dispatch: any) => {
  axiosHttp
    .get(`/rail-cards`)
    .then(({ data }) => {
      const sortedRailcards = sortBy(data, [
        function (railcard) {
          return railcard?.name;
        },
      ]);
      dispatch(setRailcards(sortedRailcards));
    })
    .catch((ex) => {
      console.log('[getRailcards] Exception : ', ex);
    });
};
