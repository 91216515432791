import * as constants from '../actions/constants';
import {
  ActionTypes,
  ArrivalDepart,
  DateTime,
  JourneyPlannerState,
  JourneyType,
  Passengers,
  Railcards,
  Station,
  ToknUTC,
  ViaAvoid,
} from '../types/journeyPlanner';
import { getCurrentDate } from '../utils/journeyplanner';

const outboundDateTime: DateTime = getCurrentDate({ minOffset: 5 }); // current time with 15 min offset
const { utc: outboundStartUTC }: DateTime = outboundDateTime;
const { utc: outboundEndUTC }: DateTime = getCurrentDate({
  hourOffset: 3,
  utcCurrent: outboundStartUTC,
});

const returnDateTime: DateTime = getCurrentDate({
  hourOffset: 8,
  utcCurrent: outboundDateTime?.utc,
});
const { utc: returnStartUTC }: DateTime = returnDateTime;
const { utc: returnEndUTC }: DateTime = getCurrentDate({
  hourOffset: 3,
  utcCurrent: returnStartUTC,
});

export const initialState: JourneyPlannerState = {
  departureStation: {
    name: 'Ashford International',
    nlc: '5004',
    crs: 'AFK',
  },
  arrivalStation: {
    name: '',
    nlc: '',
    crs: '',
  },
  travelViaOrAvoid: {
    enabled: false,
    isViaAvoid: ViaAvoid.VIA, // 0 - via / 1 - avoid
    station: {
      name: '',
      nlc: '',
      crs: '',
    },
  },
  journeyDetails: {
    outbound: {
      dateTime: outboundDateTime,
      startUTC: outboundStartUTC,
      endUTC: outboundEndUTC, // + 3hr from current time
      isArrival: ArrivalDepart.DEPARTURE,
    },
    return: {
      dateTime: returnDateTime, // + 8hr from outbound time
      startUTC: returnStartUTC,
      endUTC: returnEndUTC, // + 3hr from return time
      isArrival: ArrivalDepart.DEPARTURE,
    },
    journeyType: JourneyType.SINGLE,
  },
  passenger: {
    adult: 1,
    child: 0,
  },
  railcard: [],
};

const journeyPlanner = (
  state: JourneyPlannerState = initialState,
  action: ActionTypes
): JourneyPlannerState => {
  switch (action.type) {
    case constants.SET_DEPARTURE_STATION:
      return {
        ...state,
        departureStation: action.data as Station,
      };
    case constants.SET_ARRIVAL_STATION:
      return {
        ...state,
        arrivalStation: action.data as Station,
      };
    case constants.SET_TRAVEL_VIA_AVOID_STATION:
      return {
        ...state,
        travelViaOrAvoid: {
          ...state.travelViaOrAvoid,
          station: action.data as Station,
        },
      };

    case constants.SET_TRAVEL_VIA_VOID:
      return {
        ...state,
        travelViaOrAvoid: {
          ...state.travelViaOrAvoid,
          enabled: action.data as boolean,
        },
      };

    case constants.SET_IS_TRAVEL_VIA_OR_AVOID:
      return {
        ...state,
        ...state,
        travelViaOrAvoid: {
          ...state.travelViaOrAvoid,
          isViaAvoid: action.data as ViaAvoid,
        },
      };
    case constants.SET_PASSENGER_COUNT:
      return {
        ...state,
        passenger: action.data as Passengers,
      };
    case constants.SET_SELECTED_RAILCARD:
      return {
        ...state,
        railcard: action.data as Railcards,
      };
    case constants.SET_OUTBOUND_DATE_TIME:
      return {
        ...state,
        journeyDetails: {
          ...state.journeyDetails,
          outbound: {
            ...state.journeyDetails.outbound,
            dateTime: action.data as DateTime,
          },
        },
      };
    case constants.SET_OUTBOUND_END_UTC:
      return {
        ...state,
        journeyDetails: {
          ...state.journeyDetails,
          outbound: {
            ...state.journeyDetails.outbound,
            endUTC: action.data as ToknUTC,
          },
        },
      };
    case constants.SET_OUTBOUND_START_UTC:
      return {
        ...state,
        journeyDetails: {
          ...state.journeyDetails,
          outbound: {
            ...state.journeyDetails.outbound,
            startUTC: action.data as ToknUTC,
          },
        },
      };
    case constants.SET_OUTBOUND_IS_ARRIVAL:
      return {
        ...state,
        journeyDetails: {
          ...state.journeyDetails,
          outbound: {
            ...state.journeyDetails.outbound,
            isArrival: action.data as ArrivalDepart,
          },
        },
      };
    case constants.SET_RETURN_DATE_TIME:
      return {
        ...state,
        journeyDetails: {
          ...state.journeyDetails,
          return: {
            ...state.journeyDetails.return,
            dateTime: action.data as DateTime,
          },
        },
      };
    case constants.SET_RETURN_START_UTC:
      return {
        ...state,
        journeyDetails: {
          ...state.journeyDetails,
          return: {
            ...state.journeyDetails.return,
            startUTC: action.data as ToknUTC,
          },
        },
      };
    case constants.SET_RETURN_END_UTC:
      return {
        ...state,
        journeyDetails: {
          ...state.journeyDetails,
          return: {
            ...state.journeyDetails.return,
            endUTC: action.data as ToknUTC,
          },
        },
      };
    case constants.SET_RETURN_IS_ARRIVAL:
      return {
        ...state,
        journeyDetails: {
          ...state.journeyDetails,
          return: {
            ...state.journeyDetails.return,
            isArrival: action.data as ArrivalDepart,
          },
        },
      };
    case constants.SET_JOURNEY_TYPE:
      return {
        ...state,
        journeyDetails: {
          ...state.journeyDetails,
          journeyType: action.data as JourneyType,
        },
      };
    case constants.RESET_JOURNEY_PLANNER:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default journeyPlanner;
