import styles from './NoService.module.scss';

import NoServiceSVG from '../../../assets/exclamation.svg?react';

export default function NoService() {
  return (
    <div className={styles.screenBackground}>
      <div />
      <div className={styles.errorMidSection}>
        <NoServiceSVG />
        <span>
          <b className={styles.statusText}>We&apos;ll be right back</b>
        </span>
        <span>
          This machine is currently out of service. We apologize for any inconvenience this may
          cause.
        </span>
      </div>
      <div />
    </div>
  );
}
