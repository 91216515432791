import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
};

function Railcard({ className, fill, width = '22.75', height = '26' }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 36 36'
      className={className}
    >
      <circle cx='18' cy='18' r='18' fill={fill || '#1e1e50'} data-name='Ellipse 206' />
      <path
        fill='#fff'
        d='M21.688 16h-4.512l-6.408-2.964H5.776v-1.98h4.632l5.076-2.376H5.776V6.772h9.708L9.148 4h4.56L19.7 6.772h4.752V8.68H19.7l-4.92 2.376h9.672v1.98h-9.4z'
        data-name='Path 1297'
        transform='translate(3.223 8)'
      />
    </svg>
  );
}

export default React.memo(Railcard);
