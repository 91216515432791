import SlidingPane from 'react-sliding-pane';
import './Slider.css';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './Options.module.scss';
import Accessibility from '@/assets/Acessibility';
import LanguageSetting from '../LanguageSetting/LanguageSetting';
import { Languages } from '@/types/accessibility';
import ColorContrast from '../ColorContrast/ColorContrast';
import Scaling from '../Scaling/Scaling';

type AccessibilityInputProps = Record<string, never>;

function AccessibilityOptions({}: Readonly<AccessibilityInputProps>) {
  const [sliderVis, setSliderVis] = useState<boolean>(false);

  const {
    i18n: { language },
  } = useTranslation();

  const handleOnClick = useCallback(() => {
    setSliderVis(true);
    return undefined;
  }, []);
  return (
    <>
      <nav className={styles.options} onClick={handleOnClick}>
        <Accessibility />
        <div className={classNames(styles.circle, styles.selected)}>{language}</div>
        {/* <div
          onClick={() => window.location.reload()}
          className={styles.version}>{`${packageInfo.version}`}</div> */}
      </nav>

      <SlidingPane
        isOpen={sliderVis}
        from='bottom'
        width='100%'
        title='Done Button'
        subtitle='Optional subtitle.'
        closeIcon={<div>Close Me</div>}
        onRequestClose={() => {
          setSliderVis(false);
        }}
      >
        <div className={styles.optionsContainer}>
          <LanguageSetting appLanguage={language as Languages} />
          <ColorContrast />
          <Scaling />
        </div>
      </SlidingPane>
    </>
  );
}

export default AccessibilityOptions;
