import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSetting.module.scss';
import { Languages } from '@/types/accessibility';
type LanguageSettingProps = {
  appLanguage: Languages;
};

type LangType = {
  name: string;
  code: Languages;
};

const languages: LangType[] = [
  { name: 'English', code: Languages.ENGLISH },
  { name: 'Deutsch', code: Languages.DEUTSCH },
  { name: 'Français', code: Languages.FRANCAIS },
  { name: 'Polski', code: Languages.POLSKI },
  { name: 'Cymraeg', code: Languages.CYMRAEG },
  { name: 'Español', code: Languages.ESPANOL },
  { name: 'Italiano', code: Languages.ITALIANO },
  { name: 'Português', code: Languages.PORTUGUESE },
];

const LanguageSetting: React.FC<LanguageSettingProps> = ({ appLanguage }): React.ReactNode => {
  const {
    i18n: { changeLanguage },
  } = useTranslation();

  const handleLanguageSelect = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, data: LangType) => {
      changeLanguage(data.code);
      event.stopPropagation();
      event.preventDefault();
      return undefined;
    },
    [changeLanguage]
  );

  return (
    <div className={styles.optionsFrame}>
      <h4>Language</h4>
      {/* <ColorContrast /> */}
      <ul>
        {languages.map((language) => (
          <li key={language.code} onClick={(event: any) => handleLanguageSelect(event, language)}>
            <div
              className={classNames(styles.circle, {
                [styles.selected]: appLanguage === language.code,
              })}
            >
              {language.code}
            </div>
            {language.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSetting;
