import React from 'react';

type Props = {
  fill?: string;
};

function Passengers({ fill }: Props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22.75' height='26' viewBox='0 0 22.75 26'>
      <path
        fill={fill || '#1e1e50'}
        d='M11.375 13a6.5 6.5 0 10-6.5-6.5 6.5 6.5 0 006.5 6.5zm-2.321 2.438A9.053 9.053 0 000 24.492 1.508 1.508 0 001.508 26h19.734a1.508 1.508 0 001.508-1.508 9.053 9.053 0 00-9.05-9.054z'
      />
    </svg>
  );
}

export default React.memo(Passengers);
