import React, { HTMLAttributes, ReactNode } from 'react';

interface TextProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  size?: string;
  color?: string;
  align?: AlignSetting;
  weight?: string;
  margin?: string;
}

export const Text: React.FC<TextProps> = ({
  children,
  size,
  color,
  align,
  weight,
  margin,
  ...restProps
}) => {
  const styles: React.CSSProperties = {
    textAlign: align,
    fontFamily: 'Poppins',
    color,
    fontSize: size ?? '21px',
    margin,
  };
  return (
    <div style={styles} {...restProps}>
      {children}
    </div>
  );
};

export const TextBold: React.FC<TextProps> = ({
  children,
  size,
  color,
  align,
  weight,
  margin,
  ...restProps
}) => {
  const styles: React.CSSProperties = {
    textAlign: align,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    color,
    fontSize: size ?? '24px',
    margin,
  };
  return (
    <div style={styles} {...restProps}>
      {children}
    </div>
  );
};

export const TextNoWrap: React.FC<TextProps> = ({ children, ...restProps }) => {
  const styles: React.CSSProperties = {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
  return (
    <span style={styles} {...restProps}>
      {children}
    </span>
  );
};
