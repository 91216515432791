import React, { useCallback } from 'react';
import classNames from 'classnames';
import ColorContrastIcon from '@/assets/ColorContrastIcon';
import styles from './ColorContrast.module.scss';

const ColorContrast: React.FC = (): React.ReactNode => {
  const handleColors = useCallback((event: React.MouseEvent<HTMLLIElement>, data: string) => {
    const metaTag = document.querySelector('html');
    if (metaTag) {
      metaTag.setAttribute('hc', data); // Change the color to red
    }
    event.stopPropagation();
    event.preventDefault();
    return undefined;
  }, []);

  return (
    <div className={styles.ColorContrastFrame}>
      <h4>Screen Contrast</h4>
      <ul>
        <li onClick={(event) => handleColors(event, 'a1')}>
          <div className={classNames(styles.container)}>
            <ColorContrastIcon />
          </div>
          High Contrast
        </li>
        <li onClick={(event) => handleColors(event, 'a3')}>
          <div className={classNames(styles.container)}>
            <ColorContrastIcon rotateDeg={-90} />
          </div>
          Invert
        </li>
        <li onClick={(event) => handleColors(event, 'a0')}>
          <div className={classNames(styles.container)}>
            <ColorContrastIcon rotateDeg={-180} />
          </div>
          Default
        </li>
      </ul>
    </div>
  );
};

export default React.memo(ColorContrast);

/* <Menu menuButton={}>
<MenuItem onClick={() => handleColors("a")}>Original</MenuItem>
<MenuItem onClick={() => handleColors("a1")}>
  High Contrast
</MenuItem>
<MenuItem onClick={() => handleColors("a3")}>
  Invert Color
</MenuItem>
<MenuItem onClick={() => handleColors("a2")}>Gray Scale</MenuItem>
</Menu>
<Menu menuButton={<Language />}>
<MenuItem onClick={() => handleLanguage("en")}>English</MenuItem>
<MenuItem onClick={() => handleLanguage("pt")}>
  Portuguese
</MenuItem>
</Menu> */
