// This file is a reducer, a simple method that defines
// a initial state for the store and then a switch that
// based on what action is dispatched it will return a brand new state
import { ActionTypes, BookingBase, BookingState } from '@/types/bookings';
import * as constants from '../actions/constants';

const initialState: BookingState = {
  booking: {
    id: null,
    deviceToken: null,
    channel: null,
    brand: null,
    totalPrice: null,
    expiry: null,
    creationDate: null,
    state: null,
  },
  isCreatingBooking: false,
  qrCode: null,
  errorCreatingBooking: false,
};

const bookings = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case constants.SET_IS_CREATING_BOOKING:
      return {
        ...state,
        isCreatingBooking: action.data as boolean,
      };

    case constants.SET_BOOKING:
      return {
        ...state,
        booking: action.data as BookingBase,
      };

    case constants.SET_ERROR_CREATING_BOOKING:
      return {
        ...state,
        errorCreatingBooking: action.data as boolean,
      };

    case constants.RESET_BOOKING:
      return initialState;

    default:
      return state;
  }
};

export default bookings;
