export interface ScalingPreview {
  scale: number;
  preview: boolean;
  screenshot: string;
}

export type Accessibility = {
  languages?: Languages;
  scaling: ScalingPreview;
  screenPos?: ScreenPosition;
  screenColor?: ScreenContrast;
};

export enum ScreenContrast {
  DEFAULT = 0,
  HIGH_CONTRAST = 1,
  GRAY_SCALE = 2,
  INVERT = 3,
}

export enum ScreenPosition {
  DEFAULT = 0,
  TOP = 1,
  BOTTOM = 2,
}

export enum Languages {
  ENGLISH = 'EN',
  DEUTSCH = 'DE',
  FRANCAIS = 'FR',
  POLSKI = 'PL',
  CYMRAEG = 'CY',
  ESPANOL = 'ES',
  ITALIANO = 'IT',
  PORTUGUESE = 'PT',
}
