import React, { useCallback, useEffect, useState } from 'react';

// Styles
import styles from './Clock.module.scss';

const optionsDate: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
};
const optionsTime: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export function Clock() {
  const [date, setDate] = useState(new Date());

  const tick = () => setDate(new Date());

  const formattedDate = date.toLocaleDateString('en-GB', optionsDate);
  const formattedTime = date.toLocaleTimeString('en-GB', optionsTime);

  const handleClickOnClock = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div onClick={handleClickOnClock}>
      <time className={styles.clock}>{formattedTime}</time>
      <span className={styles.separator}>•</span>
      <span className={styles.clock}>{formattedDate}</span>
    </div>
  );
}

export default React.memo(Clock);
