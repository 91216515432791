import { memo } from 'react';
import classNames from 'classnames';
import styles from './SearchInput.module.scss';
import SearchIcon from '../../assets/SearchIcon';
import ClearActive from '../../assets/ClearActive';
import { nanoid } from 'nanoid';

type SearchInputProps = {
  placeHolder: string;
  onClick?: () => void;
  onChange?: () => void;
  onClearClicked?: () => void;
  variant?: 'big' | 'small';
  value?: string;
};

function SearchInput({
  placeHolder,
  onClick = () => {},
  onChange = () => {},
  onClearClicked = () => {},
  variant = 'big',
  value = '',
}: Readonly<SearchInputProps>) {
  const id = nanoid();
  return (
    <label htmlFor={id} className={styles.label}>
      <input
        onClick={onClick}
        name={id}
        id={id}
        readOnly
        placeholder={placeHolder}
        aria-label='copy-button'
        onChange={onChange}
        value={value}
        className={classNames(styles.input, styles[variant])}
      />
      {value.length > 0 ? (
        <ClearActive
          className={classNames(styles.inputIcon, styles[variant])}
          onClick={onClearClicked}
        />
      ) : (
        <SearchIcon onClick={onClick} className={classNames(styles.inputIcon, styles[variant])} />
      )}
    </label>
  );
}

export default memo(SearchInput);
