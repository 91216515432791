import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Home.module.scss';
import SearchInput from '../../SearchInput/SearchInput';

import PopularStation from '../../PopularStation/PopularStation';
import { appOpenJourneyPlanner } from '../../../actions/app';
import StationPickerModal from '../StationPickerModal/StationPickerModal';
import { setArrivalStation } from '../../../actions/journeyplanner';
import { Station } from '../../../types/journeyPlanner';

export default function Home() {
  const dispatch = useDispatch();
  const [isStationPickerOpen, setIsStationPickerOpen] = useState(false);
  const { t } = useTranslation();

  const handleStationSelect = useCallback(
    (station: Station) => {
      dispatch(setArrivalStation(station));
      dispatch(appOpenJourneyPlanner(true));
    },
    [dispatch]
  );

  return (
    <div className={styles.welcomeWrapper}>
      <SearchInput
        placeHolder={t('placeholders.destination')}
        onClick={() => setIsStationPickerOpen(true)}
      />
      <StationPickerModal
        onStationSelect={handleStationSelect}
        openState={isStationPickerOpen}
        onModalClose={() => setIsStationPickerOpen(false)}
      />
      <PopularStation />
    </div>
  );
}
