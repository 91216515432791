import axios from 'axios';

import { api } from '@/reducers/app';
import { wait } from '@/utils/index';
import { getSentryDetails } from '@/sentry';

const refreshToken = async () => {
  try {
    const oAuthBody = api.credentials;

    const oAuthURL: string = api.authUrl;

    const response = await axios.post<{
      access_token: string;
    }>(`${oAuthURL}/oauth/token`, oAuthBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await Promise.resolve(response.data.access_token);
  } catch (err) {
    console.error(err);
    localStorage.removeItem('token');
    throw err;
  }
};

const axiosHttp = axios.create({
  baseURL: `${api.url}`,
});

axiosHttp.interceptors.request.use(
  (config: any) => {
    const user = getSentryDetails();
    console.log(user?.id);
    const token = localStorage.getItem('token') || api.token.access_token;
    return {
      ...config,
      headers: {
        'computer-name': user?.id || 'null',
        ...(token !== null && { Authorization: `Bearer ${token}` }),
        ...config.headers,
      },
    };
  },
  (error) => Promise.reject(error)
);

axiosHttp.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log(error);
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      // un-authorized if token not valid
      try {
        await wait(3000);
        const token = await refreshToken();
        localStorage.setItem('token', token);
        originalRequest.headers.Authorization = token;
        const res = await axios(originalRequest);
        return await Promise.resolve(res);
      } catch (err) {
        console.error(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosHttp;
