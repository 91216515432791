import { createSelector } from 'reselect';
import { AppState } from '../types/app'; // Assuming you have a RootState type defined
// Change the "Any" type to RootState once all types are defined in the index.ts

export const selectAppState = (state: any): AppState => state.app;

export const selectIsAppReady = createSelector(selectAppState, (app) => app.ready);

export const selectCurrentPath = (state: AppState & { router: any }) =>
  state.router.location.pathname || '';

export const selectIsHome = createSelector(selectCurrentPath, (path) => path === '/');

export const selectCurrentState = createSelector(selectAppState, (app) => app.currentState);
