import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { SegmentInfo } from '../../types/journeyResults';
import { selectStationByCode } from '../../selectors/stations';
import { Station } from '../../types/journeyPlanner';
import './CallingPoint.css';

type CallingPointProps = {
  segmentInfo: SegmentInfo;
  lastSegment?: boolean;
  firstSegment?: boolean;
  hideCallingPoint?: ReactNode;
};

export function CallingPoint({
  segmentInfo,
  lastSegment = false,
  firstSegment = false,
  hideCallingPoint,
}: Readonly<CallingPointProps>): React.ReactNode {
  const station: Station | undefined = useSelector(selectStationByCode(segmentInfo?.station));

  if (station == undefined) {
    return <div></div>;
  }
  const date = new Date(segmentInfo?.time?.scheduledTime);
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');

  const isValidTime = !isNaN(date.getMinutes()) && !isNaN(date.getHours());

  return (
    <div
      className={classNames({
        current: lastSegment,
        highlight: lastSegment || firstSegment,
      })}
    >
      {isValidTime ? `${hours} : ${minutes} ` : ''}

      <span style={{ display: 'grid', justifyItems: 'start' }}>
        <span> {station?.name}</span>
        {segmentInfo?.platform && <span> {`Platform ${segmentInfo?.platform}`} </span>}
        {firstSegment && hideCallingPoint}
      </span>
    </div>
  );
}
