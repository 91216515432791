import React from 'react';

type Props = {
  rotateDeg?: number;
};

function ColorContrastIcon({ rotateDeg = 0 }: Props) {
  return (
    <svg width='50' height='50' transform={`rotate(${rotateDeg.toString()})`}>
      <circle cx='25' cy='25' r='25' stroke='#FFFFFF' strokeWidth='1' fill='white' />
      <path d='M0,25 a1,1 0 0,0 50,0' fill='#4A4A72' />
    </svg>
  );
}

export default React.memo(ColorContrastIcon);
