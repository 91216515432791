import React from 'react';

function Date() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='24' viewBox='0 0 21 24'>
      <path
        fill='#1e1e50'
        d='M7.125 1.125V0h-2.25v3H0v21h21V3h-4.875V0h-2.25v3h-6.75zM2.25 9h16.5v12.75H2.25z'
      />
    </svg>
  );
}

export default React.memo(Date);
