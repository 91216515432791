// This file is a reducer, a simple method that defines
// a initial state for the store and then a switch that
// based on what action is dispatched it will return a brand new state
import * as constants from '../actions/constants';
import { ActionTypes, StationState } from '../types/stations';

const initialState: StationState = {
  list: [],
  popular: [], // station
};

const stations = (state: StationState = initialState, action: ActionTypes): StationState => {
  switch (action.type) {
    case constants.SET_STATIONS:
      return {
        ...state,
        list: action.data,
      };
    case constants.SET_POPULAR_STATIONS:
      return {
        ...state,
        popular: action.data,
      };
    default:
      return state;
  }
};

export default stations;
